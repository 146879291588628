import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import { FaInstagram } from 'react-icons/fa6';
import { FaXTwitter } from 'react-icons/fa6';
import { GoMail } from 'react-icons/go';
// import Logo from '../../assets/images/Logo';
import classes from './Navigationbar.module.css';
import logo from '../../assets/images/logo.png';

const Navigationbar = () => {
  const location = useLocation();
  useEffect(() => {
    const paths = location.pathname.split('/');
    let t = 'Alon Ohad - Kayaking';
    paths.forEach((p) => {
      if (p) {
        t = `${t} | ${p}`;
      }
    });
    document.title = t;
    window.gtag('event', 'page_view', {
      page_path: location.pathname + location.search,
    });
  }, [location.pathname, location.search]);

  return (
    <Navbar collapseOnSelect className="color-nav" expand="lg" bg="">
      <Container>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Brand className={classes.Brand} as={Link} to="/">
          <Image
            src={logo}
            alt="logo"
            style={{ width: '20px', position: 'relative', top: '-3px' }}
          />
        </Navbar.Brand>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            activeKey={`/${location.pathname.split('/')[1]}`}
            className="me-auto"
          >
            <Nav.Link as={Link} to="/" eventKey="/">
              About
            </Nav.Link>
            <Nav.Link as={Link} to="/expeditions" eventKey="/expeditions">
              Expeditions
            </Nav.Link>
            <Nav.Link as={Link} to="/pictures" eventKey="/pictures">
              Pictures
            </Nav.Link>
            <Nav.Link as={Link} to="/forecast" eventKey="/forecast">
              Forecast
            </Nav.Link>
            <Nav.Link as={Link} to="/dev" eventKey="/dev">
              Dev
            </Nav.Link>
          </Nav>
          <Nav activeKey={location.pathname}>
            {/* <Nav.Link as={Link} to="/forecast" eventKey="/forecast">
              Forecast
            </Nav.Link> */}
            <Nav.Link as={Link} to="/press" eventKey="/press">
              Press
            </Nav.Link>
            <Nav.Link as={Link} to="/sponsors" eventKey="/sponsors">
              Sponsors
            </Nav.Link>
            <Nav.Link href="mailto:alon+kayak@ohad.info" eventKey="mail">
              <GoMail />
            </Nav.Link>
            <Nav.Link
              href="https://twitter.com/WorldKayaking"
              eventKey="X"
              target="_blank"
            >
              <FaXTwitter />
            </Nav.Link>
            <Nav.Link
              href="https://www.instagram.com/kayakingaroundtheworld"
              eventKey="instagram"
              target="_blank"
            >
              <FaInstagram />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigationbar;
