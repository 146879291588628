import React from 'react';
import { Container } from 'react-bootstrap';
import { GoMail } from 'react-icons/go';
import classes from './Footer.module.css';

const Footer = () => {
  return (
    <Container fluid className={classes.footerContainer}>
      <div className={classes.footer}>
        <div className={classes.footerContent}>
          <p className={classes.email}>
            <GoMail />{' '}
            <a
              href="mailto:alon+kayak@ohad.info"
              target="_blank"
              rel="noreferrer"
            >
              alon@ohad.info
            </a>
          </p>
          <p className={classes.copyright}>© 2025 Alon Ohad</p>
        </div>
      </div>
    </Container>
  );
};

export default Footer;
