import axios from 'axios';

// Create axios instance with base configuration
export const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL_GCP}`,
  headers: { 'Content-Type': 'application/json' },
  timeout: 10000,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Log error details for debugging
    if (error.response) {
      console.error('Response Error:', {
        status: error.response.status,
        data: error.response.data,
      });

      // Handle authentication errors
      if (error.response.status === 401 || error.response.status === 403) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('user');
        // Could dispatch an event or action to notify the app about auth issues
      }
    } else if (error.request) {
      console.error('Request Error:', error.request);
    } else {
      console.error('Error:', error.message);
    }

    return Promise.reject(error);
  }
);

// Cache management utilities
export const cacheKey = (endpoint, params = {}) => {
  return `api_cache_${endpoint}_${JSON.stringify(params)}`;
};

export const getCachedData = (key, expiryMinutes = 60) => {
  const cachedItem = localStorage.getItem(key);

  if (!cachedItem) return null;

  const { data, timestamp } = JSON.parse(cachedItem);
  const now = new Date().getTime();
  const expiryTime = expiryMinutes * 60 * 1000;

  // Check if cache is expired
  if (now - timestamp > expiryTime) {
    localStorage.removeItem(key);
    return null;
  }

  return data;
};

export const setCachedData = (key, data) => {
  const cacheItem = {
    data,
    timestamp: new Date().getTime(),
  };

  localStorage.setItem(key, JSON.stringify(cacheItem));
};
