import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { MdAccessTime, MdTsunami } from 'react-icons/md';
import { BsWind, BsThermometerHalf } from 'react-icons/bs';
import { LuCloudRainWind } from 'react-icons/lu';
import { GiBigWave } from 'react-icons/gi';
import { FaArrowUp } from 'react-icons/fa';
import classes from './HourlyForecastList.module.css';

/**
 * HourlyForecastList component displays a list of hourly or 3-hour interval forecast data
 *
 * @param {Array} dateLabels - Array of date/time labels
 * @param {Array} waveHeight - Array of wave height values
 * @param {Array} windWaveHeight - Array of wind wave height values
 * @param {Array} swellWaveHeight - Array of swell wave height values (optional)
 * @param {Array} temperature - Array of temperature values
 * @param {Array} precipitationProbability - Array of precipitation probability values
 * @param {Array} precipitation - Array of precipitation values
 * @param {Array} windSpeed - Array of wind speed values
 * @param {Array} windDirection - Array of wind direction values
 * @param {Object} units - Object containing units for each measurement
 * @param {boolean} isWeekView - Whether this is a weekly view (to show 3-hour intervals)
 * @param {boolean} showSwellData - Whether to show swell wave data (optional)
 * @returns {JSX.Element} The rendered component
 */
const HourlyForecastList = ({
  dateLabels,
  waveHeight,
  windWaveHeight,
  swellWaveHeight,
  temperature,
  precipitationProbability,
  precipitation,
  windSpeed,
  windDirection,
  units = {},
  isWeekView,
  showSwellData = false,
}) => {
  const [showAll, setShowAll] = useState(false);
  const initialItemsToShow = isWeekView ? 12 : 8; // Show more items initially for weekly view

  // If no data is available, return null
  if (
    !dateLabels ||
    !waveHeight ||
    !windWaveHeight ||
    dateLabels.length === 0
  ) {
    return null;
  }

  // Filter data for week view (every 3 hours)
  let filteredDateLabels = dateLabels;
  let filteredWaveHeight = waveHeight;
  let filteredWindWaveHeight = windWaveHeight;
  let filteredSwellWaveHeight = swellWaveHeight || [];
  let filteredTemperature = temperature || [];
  let filteredPrecipitationProbability = precipitationProbability || [];
  let filteredWindSpeed = windSpeed || [];
  let filteredWindDirection = windDirection || [];
  let filteredPrecipitation = precipitation || [];

  if (isWeekView) {
    // For weekly view, filter to show every 3 hours
    filteredDateLabels = dateLabels.filter((_, index) => index % 3 === 0);
    filteredWaveHeight = waveHeight.filter((_, index) => index % 3 === 0);
    filteredWindWaveHeight = windWaveHeight.filter(
      (_, index) => index % 3 === 0
    );

    if (swellWaveHeight) {
      filteredSwellWaveHeight = swellWaveHeight.filter(
        (_, index) => index % 3 === 0
      );
    }
    if (temperature) {
      filteredTemperature = temperature.filter((_, index) => index % 3 === 0);
    }
    if (precipitationProbability) {
      filteredPrecipitationProbability = precipitationProbability.filter(
        (_, index) => index % 3 === 0
      );
    }
    if (windSpeed) {
      filteredWindSpeed = windSpeed.filter((_, index) => index % 3 === 0);
    }
    if (windDirection) {
      filteredWindDirection = windDirection.filter(
        (_, index) => index % 3 === 0
      );
    }
    if (precipitation) {
      filteredPrecipitation = precipitation.filter(
        (_, index) => index % 3 === 0
      );
    }
  }

  // Determine how many items to display
  const displayItems = showAll
    ? filteredDateLabels.length
    : Math.min(initialItemsToShow, filteredDateLabels.length);
  const hasMoreItems = filteredDateLabels.length > initialItemsToShow;

  // Function to format date labels for better readability
  const formatDateLabel = (label) => {
    if (!label) return '';

    // If it's a weekly view, try to extract and format the date part
    if (isWeekView) {
      // Assuming format like "Mon 01/01 12:00"
      const parts = label.split(' ');
      if (parts.length >= 2) {
        return label; // Return the full label which already includes day
      }
    }

    return label;
  };

  // Function to get wind direction arrow with proper rotation
  const getWindDirectionArrow = (degrees) => {
    if (degrees === undefined || degrees === null) return null;

    // Add 180 degrees to point in the opposite direction (where the wind is coming from)
    const arrowRotation = (degrees + 180) % 360;

    // Get cardinal direction for the title attribute using 16-point compass rose
    const getCardinalDirection = (deg) => {
      // 16-point compass rose (each point is 22.5 degrees apart)
      const directions = [
        'N',
        'NNE',
        'NE',
        'ENE',
        'E',
        'ESE',
        'SE',
        'SSE',
        'S',
        'SSW',
        'SW',
        'WSW',
        'W',
        'WNW',
        'NW',
        'NNW',
        'N',
      ];
      const index = Math.round(deg / 22.5) % 16;
      return directions[index];
    };

    const cardinalDirection = getCardinalDirection(degrees);

    return (
      <FaArrowUp
        className="wind-direction-arrow"
        style={{
          transform: `rotate(${arrowRotation}deg)`,
          display: 'inline-block',
          marginLeft: '8px',
          verticalAlign: 'middle',
        }}
        size={14}
        color="#7f8c8d"
        title={`${cardinalDirection} ${degrees}°`}
      />
    );
  };

  // Check if we have weather data
  const hasWeatherData = temperature && temperature.length > 0;

  // Check if we have swell data
  const hasSwellData =
    showSwellData &&
    swellWaveHeight &&
    Array.isArray(swellWaveHeight) &&
    swellWaveHeight.some((val) => val > 0);

  return (
    <div className={classes.hourlyForecastContainer}>
      <h4 className={classes.hourlyForecastTitle}>
        <MdAccessTime size={20} />{' '}
        {isWeekView ? 'Weekly Forecast (3-hour intervals)' : 'Hourly Forecast'}
      </h4>

      {/* Header row - only visible on desktop */}
      <div className={classes.headerRow}>
        <div className={classes.hourlyTime}>
          <MdAccessTime size={16} />
          <span>Time</span>
        </div>
        <div className={classes.measurement}>
          <MdTsunami size={16} style={{ width: '16px' }} color="#0066cc" />
          <span>Wave Height</span>
        </div>
        <div className={classes.measurement}>
          <BsWind size={16} color="#ff6600" />
          <span>Wind Wave</span>
        </div>
        {hasSwellData && (
          <div className={classes.measurement}>
            <GiBigWave size={16} color="#3399ff" />
            <span>Swell Wave</span>
          </div>
        )}
        {hasWeatherData && (
          <>
            <div className={classes.measurement}>
              <BsThermometerHalf size={16} color="#e74c3c" />
              <span>Temp</span>
            </div>
            <div className={classes.measurement}>
              <LuCloudRainWind size={20} color="#3498db" />
              <span>Rain</span>
            </div>
            <div className={classes.measurement} style={{ marginLeft: '20px' }}>
              <BsWind size={16} color="#7f8c8d" style={{ width: '16px' }} />
              <span>Wind</span>
            </div>
          </>
        )}
      </div>

      <div className={classes.hourlyForecastList}>
        {filteredDateLabels.slice(0, displayItems).map((time, index) => (
          <div key={index} className={classes.hourlyForecastItem}>
            <div className={classes.hourlyTime}>
              <MdAccessTime size={16} />
              <span>{formatDateLabel(time)}</span>
            </div>

            <div className={classes.measurementRow}>
              <div className={classes.measurement}>
                <div className={classes.measurementLabel}>
                  <MdTsunami size={16} color="#0066cc" />
                  <span>Wave Height</span>
                </div>
                <span>
                  <MdTsunami size={16} color="#0066cc" />
                  {filteredWaveHeight[index] > 0
                    ? `${filteredWaveHeight[index]?.toFixed(2)} m`
                    : '-'}
                </span>
              </div>

              <div className={classes.measurement}>
                <div className={classes.measurementLabel}>
                  <BsWind size={16} color="#ff6600" />
                  <span>Wind Wave</span>
                </div>
                <span>
                  <BsWind size={16} color="#ff6600" />
                  {filteredWindWaveHeight[index] > 0
                    ? `${filteredWindWaveHeight[index]?.toFixed(2)} m`
                    : '-'}
                </span>
              </div>

              {hasSwellData && (
                <div className={classes.measurement}>
                  <div className={classes.measurementLabel}>
                    <GiBigWave size={16} color="#3399ff" />
                    <span>Swell Wave</span>
                  </div>
                  <span>
                    <GiBigWave size={16} color="#3399ff" />
                    {filteredSwellWaveHeight[index] > 0
                      ? `${filteredSwellWaveHeight[index]?.toFixed(2)} m`
                      : '-'}
                  </span>
                </div>
              )}

              {hasWeatherData && (
                <>
                  <div className={classes.measurement}>
                    <div className={classes.measurementLabel}>
                      <BsThermometerHalf size={16} color="#e74c3c" />
                      <span>Temperature</span>
                    </div>
                    <span>
                      <BsThermometerHalf size={16} color="#e74c3c" />
                      {filteredTemperature[index]?.toFixed(1) || 'N/A'}
                      {units.temperature_2m || '°C'}
                    </span>
                  </div>

                  <div className={classes.measurement}>
                    <div className={classes.measurementLabel}>
                      <LuCloudRainWind size={20} color="#3498db" />
                      <span>Rain</span>
                    </div>
                    <span>
                      <LuCloudRainWind size={20} color="#3498db" />
                      {filteredPrecipitation[index] > 0 ||
                      filteredPrecipitationProbability[index] > 0 ? (
                        <>
                          {filteredPrecipitation[index] > 0 &&
                            `${filteredPrecipitation[index]?.toFixed(1)}mm `}
                          {filteredPrecipitationProbability[index] !== undefined
                            ? `(${filteredPrecipitationProbability[index]}${
                                units.precipitation_probability || '%'
                              })`
                            : ''}
                        </>
                      ) : (
                        '-'
                      )}
                    </span>
                  </div>

                  <div className={classes.measurement}>
                    <div className={classes.measurementLabel}>
                      <BsWind
                        size={16}
                        color="#7f8c8d"
                        style={{ width: '16px' }}
                      />
                      <span>Wind</span>
                    </div>
                    <span>
                      <BsWind
                        size={16}
                        color="#7f8c8d"
                        style={{ width: '16px' }}
                      />
                      {filteredWindSpeed[index]?.toFixed(1) || 'N/A'}
                      {units.wind_speed_10m || 'km/h'}
                      {getWindDirectionArrow(filteredWindDirection[index])}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Show More/Less button */}
      {hasMoreItems && (
        <div className={classes.showMoreContainer}>
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => setShowAll(!showAll)}
            className={classes.showMoreButton}
          >
            {showAll ? 'Show Less' : 'Show More'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default HourlyForecastList;
