import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getPictures } from '../api/tripsApi';
import { isSuccessResponse } from '../api/apiUtils';
import TheGallery from './gallery/TheGallery';
import { useScrollToTop } from './utils/tools';
import Loader from './loader/Loader';

const Pictures = () => {
  const { galleryId } = useParams();
  const [pics, setPictures] = useState([]);
  const [title, setTitle] = useState('');
  const [year, setYear] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [comment, setComment] = useState('');

  useEffect(() => {
    const getAllPictures = async () => {
      if (!galleryId) return;

      setIsLoading(true);
      setError(null);

      try {
        const response = await getPictures({ galleryId });

        if (isSuccessResponse(response)) {
          const results = response.data;

          const pictures = results.pictures.map((pic) => {
            return {
              id: pic._id,
              _id: pic._id,
              galleryId: pic.galleryId,
              picNumber: pic.picNumber,
              commentTitle: pic.commentTitle,
              comment: pic.comment,
              original: `${process.env.REACT_APP_GCP_URL}/${pic.image}`,
              thumbnail: `${process.env.REACT_APP_GCP_URL}/${pic.image}`,
              fullscreen: true,
              originalAlt: pic.comment,
              description: pic.commentTitle,
            };
          });

          setPictures(pictures);
          setTitle(results.title);
          setYear(results.gallery?.year || '');
          setComment(results.gallery?.comment || '');
        } else {
          console.error('Error fetching pictures:', response.message);
          setError(response.message || 'Failed to load pictures');
        }
      } catch (error) {
        console.error('Error in getAllPictures:', error);
        setError('An unexpected error occurred while loading pictures');
      } finally {
        setIsLoading(false);
      }
    };

    getAllPictures();
  }, [galleryId]);

  useScrollToTop();

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return (
      <div style={{ textAlign: 'center', marginTop: '15px' }}>
        <h3>Error</h3>
        <p>{error}</p>
      </div>
    );
  }

  if (!pics || pics.length === 0) {
    return (
      <div style={{ textAlign: 'center', marginTop: '15px' }}>
        <p>No pictures found for this gallery</p>
      </div>
    );
  }

  return (
    <>
      <h2>
        {title} - {year}
      </h2>
      <TheGallery images={pics} showLoader={false} />
    </>
  );
};
export default Pictures;
