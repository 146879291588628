import { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Button from 'react-bootstrap/Button';
import { MdWaves, MdOutlineWaterDrop } from 'react-icons/md';

// API imports
import { getForecastByCoordinates, getSurfSpots } from '../api/forecastApi';
import { isSuccessResponse } from '../api/apiUtils';

// Component imports
import TheSpinner from './utils/TheSpinner';
import SurfSpotsMap from './map/SurfSpotsMap';
import QuickAccessBeaches from './QuickAccessBeaches';
import HourlyForecastList from './HourlyForecastList';

// Styles
import '../components/map/SurfSpotsMap.css';
import classes from './Forecast.module.css';

const Forecast = () => {
  // State management
  const [todayForecast, setTodayForecast] = useState(null);
  const [thisWeekForecast, setThisWeekForecast] = useState(null);
  const [forecastData, setForecastData] = useState(null);
  const [today, setToday] = useState(true);
  const [title, setTitle] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showSurfSpotsMap, setShowSurfSpotsMap] = useState(false);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [surfSpots, setSurfSpots] = useState([]);
  const [loadingSpots, setLoadingSpots] = useState(false);

  // Create chart configuration from forecast data
  const createChartOptions = ({
    dateLabel,
    timeLabel,
    dayOnly,
    waveHeight,
    windWaveHeight,
    swellWaveHeight,
    startDate,
  }) => {
    // Ensure we have valid data arrays
    const safeWaveHeight = waveHeight
      ? waveHeight.map((val) => (val === null ? 0 : val))
      : [];
    const safeWindWaveHeight = windWaveHeight
      ? windWaveHeight.map((val) => (val === null ? 0 : val))
      : [];
    const safeSwellWaveHeight = swellWaveHeight
      ? swellWaveHeight.map((val) => (val === null ? 0 : val))
      : [];

    // Determine if we should show swell data
    const hasSwellData =
      swellWaveHeight &&
      Array.isArray(swellWaveHeight) &&
      swellWaveHeight.some((val) => val > 0);

    const series = [
      {
        name: 'Wave Height',
        data: safeWaveHeight,
        color: '#0066cc',
      },
      {
        name: 'Wind Wave Height',
        data: safeWindWaveHeight,
        color: '#ff6600',
      },
    ];

    // Add swell wave height series if data is available
    if (hasSwellData) {
      series.push({
        name: 'Swell Wave Height',
        data: safeSwellWaveHeight,
        color: '#3399ff',
      });
    }

    return {
      title: {
        text: 'Wave Height Forecast',
      },
      type: 'line',
      xAxis: {
        title: {
          text: 'Time',
        },
        categories: dateLabel || [],
        type: 'datetime',
      },
      yAxis: {
        title: {
          text: 'Wave Height (m)',
        },
        min: 0,
      },
      series: series,
      tooltip: {
        formatter: function () {
          return `<b>${this.series.name}</b><br/>
                Time: ${this.x}<br/>
                Height: ${this.y.toFixed(2)} m`;
        },
      },
      plotOptions: {
        line: {
          marker: {
            enabled: true,
            radius: 3,
          },
          lineWidth: 2,
        },
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                enabled: false,
              },
            },
          },
        ],
      },
    };
  };

  // Process forecast data and update state
  const processForecastData = (response, spotName) => {
    if (!isSuccessResponse(response)) {
      console.error('Error fetching forecast:', response.message);
      setError(response.message || 'Failed to load forecast data');
      return;
    }

    const { weekForecast, oneDayForecast } = response.data;

    setTitle(spotName);

    // Store the raw forecast data for the hourly list
    setForecastData({
      weekForecast,
      oneDayForecast,
    });

    setThisWeekForecast(
      createChartOptions({
        dateLabel: weekForecast.dateLabel,
        timeLabel: weekForecast.timeLable,
        dayOnly: weekForecast.dayOnly,
        waveHeight: weekForecast.wave_height,
        windWaveHeight: weekForecast.wind_wave_height,
        swellWaveHeight: weekForecast.swell_wave_height,
        startDate: weekForecast.localTime[0],
      })
    );

    setTodayForecast(
      createChartOptions({
        dateLabel: oneDayForecast.oneDayDateLabels,
        timeLabel: oneDayForecast.oneDayTimeLables,
        dayOnly: oneDayForecast.oneDayDayOnly,
        waveHeight: oneDayForecast.oneDayWaveHeight,
        windWaveHeight: oneDayForecast.oneDayWindWaveHeight,
        swellWaveHeight: oneDayForecast.oneDaySwellWaveHeight,
        startDate: oneDayForecast.oneDayLocalTime[0],
      })
    );
  };

  // Fetch surf spots on component mount
  useEffect(() => {
    const fetchSurfSpots = async () => {
      setLoadingSpots(true);
      try {
        const response = await getSurfSpots();
        if (isSuccessResponse(response) && Array.isArray(response.data)) {
          setSurfSpots(response.data);
        }
      } catch (error) {
        console.error('Error fetching surf spots:', error);
      } finally {
        setLoadingSpots(false);
      }
    };

    fetchSurfSpots();
  }, []);

  // Handle forecast display mode
  useEffect(() => {
    if (today && todayForecast) {
      setToday(true);
    } else if (!today && thisWeekForecast) {
      setToday(false);
    }
  }, [today, todayForecast, thisWeekForecast]);

  // Get forecast by coordinates
  const getForecastByCoords = async (latitude, longitude, name) => {
    setIsLoading(true);
    setError(null);
    setSelectedCoordinates({ latitude, longitude });

    try {
      const response = await getForecastByCoordinates(latitude, longitude);
      const spotName =
        name || `Forecast for ${latitude.toFixed(4)}, ${longitude.toFixed(4)}`;

      processForecastData(response, spotName);
    } catch (error) {
      console.error('Error in getForecastByCoords:', error);
      setError('An unexpected error occurred while loading forecast data');
    } finally {
      setIsLoading(false);
    }
  };

  // Get forecast by beach name
  const getForecastByBeachName = (beachName) => {
    // Find the beach in our surf spots data
    const beach = surfSpots.find(
      (spot) =>
        spot.name.toLowerCase().includes(beachName.toLowerCase()) ||
        (beachName.toLowerCase() === 'hayarkon' &&
          spot.name.toLowerCase().includes('tel aviv'))
    );

    if (beach && beach?.location?.coordinates) {
      // In GeoJSON format, coordinates are [longitude, latitude]
      const longitude = beach.location.coordinates[0];
      const latitude = beach.location.coordinates[1];
      // Pass coordinates in the correct order
      getForecastByCoords(latitude, longitude, beach.name);
    } else {
      setError(`Could not find data for ${beachName}`);
    }
  };

  // Toggle surf spots map visibility
  const toggleSurfSpotsMap = () => {
    setShowSurfSpotsMap(!showSurfSpotsMap);
  };

  // Handle map close event
  const handleMapClose = (data) => {
    setShowSurfSpotsMap(false);

    // Check if we received forecast data from the map
    if (data && data.action === 'fetchForecast') {
      const { latitude, longitude, name } = data.data;
      getForecastByCoords(latitude, longitude, name);
    }
  };

  return (
    <>
      <h2 className={classes.titleContainer}>
        <MdOutlineWaterDrop size={24} color="#0066cc" />
        Forecast
      </h2>

      <div>
        {/* Quick access beach buttons */}
        <QuickAccessBeaches
          surfSpots={surfSpots}
          isLoading={isLoading}
          onBeachSelect={getForecastByBeachName}
          loadingSpots={loadingSpots}
        />

        {/* Forecast type toggle button */}
        <div className={classes.buttonContainer}>
          {(todayForecast || thisWeekForecast) && (
            <Button
              variant="light"
              onClick={() => setToday(!today)}
              title={!today ? 'Set One Day Forecast' : 'Set Week Forecast'}
              className={classes.forecastButton}
              disabled={isLoading}
            >
              <MdWaves size={14} />
              {!today ? 'Set One Day Forecast' : 'Set Week Forecast'}
            </Button>
          )}
        </div>

        <div className={classes.centerText}>
          <h3>{title || 'Select a location'}</h3>
        </div>
      </div>

      {/* Display selected coordinates if available */}
      {selectedCoordinates && (
        <div className={classes.coordinatesInfo}>
          Coordinates: {selectedCoordinates.latitude.toFixed(4)},{' '}
          {selectedCoordinates.longitude.toFixed(4)}
        </div>
      )}

      {/* Loading indicator */}
      {isLoading && (
        <div className={classes.spinnerContainer}>
          <TheSpinner />
        </div>
      )}

      {/* Error message */}
      {error && (
        <div className={classes.errorContainer}>
          <h3>Error</h3>
          <p>{error}</p>
        </div>
      )}

      {/* Forecast chart */}
      {!isLoading && !error && todayForecast && (
        <HighchartsReact
          highcharts={Highcharts}
          options={today ? todayForecast : thisWeekForecast}
        />
      )}

      {/* Hourly Forecast List */}
      {!isLoading && !error && forecastData && (
        <HourlyForecastList
          dateLabels={
            today
              ? forecastData.oneDayForecast.oneDayDateLabels
              : forecastData.weekForecast.dateLabel
          }
          waveHeight={
            today
              ? forecastData.oneDayForecast.oneDayWaveHeight
              : forecastData.weekForecast.wave_height
          }
          windWaveHeight={
            today
              ? forecastData.oneDayForecast.oneDayWindWaveHeight
              : forecastData.weekForecast.wind_wave_height
          }
          swellWaveHeight={
            today
              ? forecastData.oneDayForecast.oneDaySwellWaveHeight
              : forecastData.weekForecast.swell_wave_height
          }
          temperature={
            today
              ? forecastData.oneDayForecast.oneDayTemperature
              : forecastData.weekForecast.temperature_2m
          }
          precipitationProbability={
            today
              ? forecastData.oneDayForecast.oneDayPrecipitationProbability
              : forecastData.weekForecast.precipitation_probability
          }
          precipitation={
            today
              ? forecastData.oneDayForecast.oneDayPrecipitation
              : forecastData.weekForecast.precipitation
          }
          windSpeed={
            today
              ? forecastData.oneDayForecast.oneDayWindSpeed
              : forecastData.weekForecast.wind_speed_10m
          }
          windDirection={
            today
              ? forecastData.oneDayForecast.oneDayWindDirection
              : forecastData.weekForecast.wind_direction_10m
          }
          units={forecastData.units}
          isWeekView={!today}
          showSwellData={true}
        />
      )}

      {/* No data message */}
      {!isLoading && !error && !todayForecast && title && (
        <div className={classes.noDataContainer}>
          <h4>No Forecast Data Available</h4>
          <p>
            We couldn't find forecast data for this location. Please try another
            spot.
          </p>
        </div>
      )}

      {/* Surf Spots Map Button */}
      <button
        type="button"
        className={`surf-spots-button ${classes.surfSpotsButton}`}
        onClick={toggleSurfSpotsMap}
        title="View Surf Spots"
        aria-label="View Surf Spots"
      >
        <MdWaves size={24} />
      </button>

      {/* Surf Spots Map Modal */}
      <SurfSpotsMap
        show={showSurfSpotsMap}
        onHide={handleMapClose}
        surfSpots={surfSpots.length > 0 ? surfSpots : undefined}
      />
    </>
  );
};

export default Forecast;
