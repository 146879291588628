import React, { useEffect, useState, useRef } from 'react';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  ZoomControl,
  LayersControl,
  useMap,
} from 'react-leaflet';
import { BsFullscreen, BsFullscreenExit } from 'react-icons/bs';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import DOMPurify from 'dompurify'; // Import DOMPurify for sanitizing HTML content
// import { formatDate } from '../utils/tools';
import customMarkerIcon from '../../assets/images/mapMarker.svg';
import Modal from 'react-bootstrap/Modal';
import './LeafletMap.css';
import { MdDateRange, MdAnchor, MdOutlineLocationOn } from 'react-icons/md';
import { FaArrowUpLong, FaRoute } from 'react-icons/fa6';
import { BsWind } from 'react-icons/bs';
import {
  degToDms,
  htmlParser,
  breakLines,
  getDegreesDirection,
  getWindDegreesDirection,
} from '../utils/tools';

const { BaseLayer } = LayersControl;

const MapBounds = ({ points }) => {
  const map = useMap();
  useEffect(() => {
    if (map && points.length > 0) {
      // Create a new bounds object
      const bounds = L.latLngBounds();
      // Loop through the points and extend the bounds
      points.forEach((point) => {
        const { latitude, longitude } = point;
        if (latitude && longitude) {
          bounds.extend([latitude, longitude]);
        }
      });
      // Fit the map view to the bounds
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [map, points]);
  return null;
};

const FullscreenControl = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const map = useMap();

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      // Request fullscreen mode
      const mapElement = map.getContainer();
      if (mapElement.requestFullscreen) {
        mapElement.requestFullscreen();
      } else if (mapElement.webkitRequestFullscreen) {
        mapElement.webkitRequestFullscreen();
      } else if (mapElement.msRequestFullscreen) {
        mapElement.msRequestFullscreen();
      }
    } else {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen);
  };

  return (
    <div className="leaflet-control leaflet-bar">
      <div onClick={toggleFullscreen} className="fullscreen-control">
        {isFullscreen ? (
          <BsFullscreenExit size={20} />
        ) : (
          <BsFullscreen size={20} />
        )}
      </div>
    </div>
  );
};

const LeafletMap = ({ points }) => {
  const [selectedPoint, setSelectedPoint] = useState(null);
  const mapRef = useRef(null);

  const customIcon = new L.Icon({
    iconUrl: customMarkerIcon,
    iconSize: [20, 32],
  });

  // Add key prop to MapContainer
  const mapKey = points.map((p) => p._id).join('-');

  // Add function to find current point index and get next/prev points
  const getCurrentPointIndex = () => {
    return points.findIndex((p) => p._id === selectedPoint?._id);
  };

  const handlePrevPoint = () => {
    const currentIndex = getCurrentPointIndex();
    if (currentIndex < points.length - 1) {
      setSelectedPoint(points[currentIndex + 1]);
    }
  };

  const handleNextPoint = () => {
    const currentIndex = getCurrentPointIndex();
    if (currentIndex > 0) {
      setSelectedPoint(points[currentIndex - 1]);
    }
  };

  const getLoc = (latitude, longitude) => {
    if (longitude && latitude) {
      return `${degToDms(latitude, false)};${degToDms(longitude, true)}`;
    }
    return '';
  };

  const PointDirection = ({ direction, wind }) => {
    if (direction) {
      let degrees = getDegreesDirection(direction);
      if (wind) {
        degrees = getWindDegreesDirection(direction);
      }
      return (
        <span>
          <FaArrowUpLong style={{ rotate: `${degrees}deg` }} />
        </span>
      );
    }
    return '';
  };

  useEffect(() => {
    return () => {
      // Cleanup function
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, []);

  // Add this function to store map reference
  const onMapCreate = (map) => {
    mapRef.current = map;
  };

  return (
    <>
      <MapContainer
        key={mapKey}
        center={[0, 0]}
        zoom={2}
        style={{ height: '500px', width: '100%' }}
        zoomControl={false}
        ref={onMapCreate}
      >
        <LayersControl position="bottomright">
          <BaseLayer checked name="OpenStreetMap">
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </BaseLayer>
          <BaseLayer name="Satellite">
            <TileLayer
              attribution='&copy; <a href="https://www.esri.com/en-us/home">Esri</a>'
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            />
          </BaseLayer>
          <BaseLayer name="Topographic">
            <TileLayer
              attribution='&copy; <a href="https://www.esri.com/en-us/home">Esri</a>'
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
            />
          </BaseLayer>
        </LayersControl>
        {points.map((point) => {
          const { latitude, longitude, _id } = point;
          if (latitude && longitude) {
            return (
              <Marker
                key={_id}
                position={[latitude, longitude]}
                icon={customIcon}
                eventHandlers={{
                  click: () => setSelectedPoint(point),
                }}
              />
            );
          }
          return null;
        })}
        <MapBounds points={points} />
        <ZoomControl position="topright" />
        <FullscreenControl />
      </MapContainer>

      <Modal
        show={selectedPoint !== null}
        onHide={() => setSelectedPoint(null)}
        centered
      >
        <Modal.Header closeButton>
          {selectedPoint && (
            <Modal.Title
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  `${selectedPoint.pointName} - ${
                    selectedPoint.pointNumber
                  } (${selectedPoint.date.substring(0, 10)})`
                ),
              }}
            ></Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          {selectedPoint && (
            <>
              <div className="point-info-header">
                <div className="point-info-item">
                  <MdAnchor /> {htmlParser(selectedPoint.pointName)}
                </div>
                <div className="point-info-item">
                  <MdDateRange /> {selectedPoint.date.substring(0, 10)}
                </div>
                {selectedPoint.latitude && selectedPoint.longitude ? (
                  <div className="point-info-item">
                    <MdOutlineLocationOn />{' '}
                    {getLoc(selectedPoint.latitude, selectedPoint.longitude)}
                  </div>
                ) : null}
                {selectedPoint.distanceNoticalMiles &&
                selectedPoint.distanceNoticalMiles !== 0 ? (
                  <div className="point-info-item">
                    <FaRoute /> {`${selectedPoint.distanceNoticalMiles} nm `}
                    {`(${(selectedPoint.distanceNoticalMiles * 1.852).toFixed(
                      1
                    )} km) `}
                    <PointDirection
                      direction={selectedPoint.paddleDirection}
                      wind={false}
                    />
                  </div>
                ) : null}
                {selectedPoint.windStrength > 0 && (
                  <div className="point-info-item">
                    <BsWind /> {`${selectedPoint.windStrength} knots `}
                    <PointDirection
                      wind
                      direction={selectedPoint.windDirection}
                    />
                  </div>
                )}
              </div>
              <div className="modal-nav-buttons">
                <button
                  onClick={handlePrevPoint}
                  disabled={getCurrentPointIndex() === points.length - 1}
                  className="modal-nav-button"
                >
                  Previous Point
                </button>
                <button
                  onClick={handleNextPoint}
                  disabled={getCurrentPointIndex() === 0}
                  className="modal-nav-button"
                >
                  Next Point
                </button>
              </div>
              <div style={{ padding: '0 20px' }}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(selectedPoint.freeText),
                  }}
                ></p>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LeafletMap;
