import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import { useScrollToTop } from './utils/tools';
import classes from './About.module.css';
import { Link } from 'react-router-dom';

const About = () => {
  useScrollToTop();
  return (
    <Container fluid className={classes.AboutContainer}>
      <Col className={classes.About}>
        <img
          src="https://storage.googleapis.com/by-data/ohad/about_image.jpg"
          alt="About page"
          className={classes.AboutImg}
        />
        <p>
          Hey there! I'm Alon, and I live for the thrill of sea kayaking and
          exploring wild places. For over two decades, I've been chasing
          adventures on the water, paddling through some of the world's most
          challenging seas. From the icy waters of the Arctic to the rugged
          coastlines of Australia and New Zealand, each expedition has been a
          unique story of discovery and challenge.
        </p>
        <p>
          Some of my most memorable paddles have been through the dramatic
          seascapes of the Faroe Islands and along Ireland's wild coast. I've
          also ventured into white-water territory, taking on the exciting
          rapids of the White Nile. When I'm not in my kayak, I'm usually
          planning the next adventure or sharing my experiences through guiding
          and presentations.
        </p>
        <p>
          I'd like to extend a special thank you to Nigel Dennis from{' '}
          <a href="https://seakayakinguk.com" target="_blank" rel="noreferrer">
            seakayakinguk.com
          </a>
          , whose guidance and support have been invaluable throughout my
          expeditions over the years. You can find more about my amazing
          sponsors and supporters on my{' '}
          <Link to="/sponsors">sponsors page</Link>.
        </p>
        <p>
          While the ocean is my playground, I also lead a parallel life in the
          tech world. I'm a hands-on engineering manager, building teams and
          solving complex technical challenges. If you're curious about my
          professional journey, check out my{' '}
          <Link to="/dev">development work</Link>.
        </p>
        <p>
          Got any questions about kayaking adventures or interested in joining
          an expedition? Drop me a line at{' '}
          <a href="mailto:alon+kayak@ohad.info">alon@ohad.info</a>
        </p>
        <p>See you on the water!</p>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: '2rem',
            marginTop: '2rem',
          }}
        >
          <iframe
            className="rumble"
            width="640"
            height="360"
            title="9Lives - Svalbard Expedition"
            src="https://rumble.com/embed/v48ajec/?pub=f7o29"
            allowFullScreen
          ></iframe>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: '2rem',
          }}
        >
          <iframe
            className="rumble"
            width="640"
            height="360"
            src="https://rumble.com/embed/v489pyr/?pub=f7o29"
            allowFullScreen
            title="Silverback Uganda"
          ></iframe>
        </div>
      </Col>
    </Container>
  );
};

export default About;
