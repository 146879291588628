import React from 'react';
import './Loader.css';
import kayakImage from '../../assets/images/kayak.png';

const Loader = () => {
  return (
    <div className="loader-container">
      <img src={kayakImage} alt="Loading..." className="kayak-loader" />
    </div>
  );
};

export default Loader;
