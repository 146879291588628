import { api, cacheKey, getCachedData, setCachedData } from './Api';
import { handleError, formatResponse } from './apiUtils';
import moment from 'moment';

const CACHE_EXPIRY_MINUTES = 60; // Cache forecast data for 1 hour

/**
 * Get forecast data for a specific location
 * @param {string} location - The location identifier
 * @returns {Promise<Object>} Forecast data or error response
 */
export const getForecast = async (location) => {
  try {
    // Check cache first
    const cacheKeyString = cacheKey(`forecast_${location}`);
    const cachedData = getCachedData(cacheKeyString, CACHE_EXPIRY_MINUTES);

    if (cachedData) {
      console.log('Using cached forecast data');
      return formatResponse(cachedData);
    }

    // If not in cache, fetch from API
    const response = await api.get(`/forecast?point=${location}`, {
      headers: { 'Content-Type': 'application/json' },
    });

    if (!response || !response.data) {
      throw new Error('Invalid response from forecast API');
    }

    // Process the data
    const { localTime, dateLabel, timeLable, dayOnly } = getLocalTimes(
      response.data.marineForecast
    );

    response.data.marineForecast.hourly.localTime = localTime;
    response.data.marineForecast.hourly.dateLabel = dateLabel;
    response.data.marineForecast.hourly.timeLable = timeLable;
    response.data.marineForecast.hourly.dayOnly = dayOnly;

    const oneDayForecast = getForecastSubset(response.data.marineForecast);

    const processedData = {
      oneDayForecast,
      weekForecast: response.data.marineForecast.hourly,
    };

    // Cache the processed data
    setCachedData(cacheKeyString, processedData);

    return formatResponse(processedData);
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Get list of available surf spots
 * @returns {Promise<Object>} List of surf spots or error response
 */
export const getSurfSpots = async () => {
  try {
    // Check cache first
    const cacheKeyString = cacheKey('forecast_surfspots');
    const cachedData = getCachedData(cacheKeyString, CACHE_EXPIRY_MINUTES);

    if (cachedData) {
      console.log('Using cached surf spots data');
      return formatResponse(cachedData);
    }

    // If not in cache, fetch from API
    const response = await api.get('/forecast/surfspots', {
      headers: { 'Content-Type': 'application/json' },
    });

    if (!response || !response.data) {
      throw new Error('Invalid response from surf spots API');
    }

    // Cache the data
    setCachedData(cacheKeyString, response.data);

    return formatResponse(response.data);
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Get forecast data for specific coordinates
 * @param {number} latitude - The latitude
 * @param {number} longitude - The longitude
 * @returns {Promise<Object>} Forecast data or error response
 */
export const getForecastByCoordinates = async (latitude, longitude) => {
  try {
    // Check cache first
    const cacheKeyString = cacheKey(`forecast_${latitude}_${longitude}`);
    const cachedData = getCachedData(cacheKeyString, CACHE_EXPIRY_MINUTES);

    if (cachedData) {
      console.log('Using cached forecast data for coordinates');
      return formatResponse(cachedData);
    }

    // If not in cache, fetch from API
    console.log(`Fetching forecast for coordinates: ${latitude}, ${longitude}`);
    const response = await api.get(
      `/forecast?lon=${longitude}&lat=${latitude}`,
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );

    if (!response || !response.data) {
      throw new Error('Invalid response from forecast API');
    }

    // Handle the new response structure
    const forecastData = response.data;

    // Check if we have both marine and weather forecast data
    if (forecastData.marineData && forecastData.weatherData) {
      console.log('Processing combined marine and weather forecast data');

      // Process and combine the data
      const processedData = processCombinedForecastData(
        forecastData.weatherData,
        forecastData.marineData
      );

      // Cache the processed data
      setCachedData(cacheKeyString, processedData);

      return formatResponse(processedData);
    } else {
      throw new Error('Missing marine or weather data in the API response');
    }
  } catch (error) {
    console.error('Error in getForecastByCoordinates:', error);

    // Provide more specific error messages
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const status = error.response.status;
      if (status === 404) {
        return handleError(
          new Error('No forecast data available for these coordinates')
        );
      } else if (status >= 500) {
        return handleError(
          new Error('Server error while fetching forecast data')
        );
      }
    } else if (error.request) {
      // The request was made but no response was received
      return handleError(
        new Error(
          'No response from forecast server. Please check your connection'
        )
      );
    }

    return handleError(error);
  }
};

/**
 * Process and combine both weather and marine forecast data
 * @param {Object} weatherData - The weather forecast data from the API
 * @param {Object} marineData - The marine forecast data from the API
 * @returns {Object} Combined and processed forecast data
 */
const processCombinedForecastData = (weatherData, marineData) => {
  // Validate that both data sets have time arrays and they are arrays
  if (!weatherData.time || !Array.isArray(weatherData.time)) {
    throw new Error('Invalid weather data structure');
  }

  if (!marineData.time || !Array.isArray(marineData.time)) {
    throw new Error('Invalid marine data structure');
  }

  // Check if weather and marine time arrays have the same length
  if (weatherData.time.length !== marineData.time.length) {
    console.warn('Weather and marine data have different time array lengths');
    // We'll continue processing but this might cause issues
  }

  // Process the time data (using weather data's time array)
  const { localTime, dateLabel, timeLable, dayOnly } = getLocalTimesFromArray(
    weatherData.time
  );

  // Create a combined hourly data structure with all available data
  const hourlyData = {
    // Time data
    time: weatherData.time,
    localTime,
    dateLabel,
    timeLable,
    dayOnly,

    // Weather data
    temperature_2m:
      weatherData.temperature_2m || Array(weatherData.time.length).fill(0),
    precipitation_probability:
      weatherData.precipitation_probability ||
      Array(weatherData.time.length).fill(0),
    precipitation:
      weatherData.precipitation || Array(weatherData.time.length).fill(0),
    rain: weatherData.rain || Array(weatherData.time.length).fill(0),
    cloud_cover:
      weatherData.cloud_cover || Array(weatherData.time.length).fill(0),
    wind_speed_10m:
      weatherData.wind_speed_10m || Array(weatherData.time.length).fill(0),
    wind_direction_10m:
      weatherData.wind_direction_10m || Array(weatherData.time.length).fill(0),
    wind_gusts_10m:
      weatherData.wind_gusts_10m || Array(weatherData.time.length).fill(0),

    // Marine data
    wave_height:
      marineData.wave_height || Array(weatherData.time.length).fill(0),
    wave_direction:
      marineData.wave_direction || Array(weatherData.time.length).fill(0),
    wave_period:
      marineData.wave_period || Array(weatherData.time.length).fill(0),
    wind_wave_height:
      marineData.wind_wave_height || Array(weatherData.time.length).fill(0),
    swell_wave_height:
      marineData.swell_height || // Note: field name is swell_height not swell_wave_height
      Array(weatherData.time.length).fill(0),
    swell_wave_direction:
      marineData.swell_direction || // Note: field name is swell_direction not swell_wave_direction
      Array(weatherData.time.length).fill(0),
    swell_wave_period:
      marineData.swell_period || // Note: field name is swell_period not swell_wave_period
      Array(weatherData.time.length).fill(0),
  };

  // Create the one day forecast subset
  const oneDayForecast = getCombinedForecastSubset(hourlyData);

  // Return the processed data in our expected format
  return {
    oneDayForecast,
    weekForecast: hourlyData,
    units: {
      // We don't have units in the response, so we'll use defaults
      temperature_2m: '°C',
      precipitation_probability: '%',
      wind_speed_10m: 'km/h',
      wave_height: 'm',
      wind_wave_height: 'm',
      swell_wave_height: 'm',
    },
  };
};

/**
 * Create a one-day forecast subset with both weather and marine data
 * @param {Object} hourlyData - Combined hourly data
 * @returns {Object} One-day forecast subset
 */
const getCombinedForecastSubset = (hourlyData) => {
  const {
    localTime,
    dateLabel,
    timeLable,
    dayOnly,
    // Weather data
    temperature_2m,
    precipitation_probability,
    precipitation,
    rain,
    cloud_cover,
    wind_speed_10m,
    wind_direction_10m,
    wind_gusts_10m,
    // Marine data
    wave_height,
    wave_direction,
    wave_period,
    wind_wave_height,
    swell_wave_height,
    swell_wave_direction,
    swell_wave_period,
  } = hourlyData;

  const now = moment();
  const twentyFourHoursFromNow = moment().add(24, 'hours');

  let startIndex = null;
  let endIndex = null;

  for (let i = 0; i < localTime.length; i++) {
    const date = moment(localTime[i]);
    if (date.isSameOrAfter(now) && startIndex === null) {
      startIndex = i;
    }
    if (date.isSameOrAfter(twentyFourHoursFromNow)) {
      endIndex = i;
      break;
    }
  }

  // If we couldn't find valid indices, use defaults
  if (startIndex === null) startIndex = 0;
  if (endIndex === null) endIndex = Math.min(startIndex + 24, localTime.length);

  // Check if swell data exists
  const hasSwellData =
    swell_wave_height &&
    Array.isArray(swell_wave_height) &&
    swell_wave_height.some((val) => val > 0);

  // Create one day forecast with all properties
  return {
    // Time data
    oneDayLocalTime: localTime.slice(startIndex, endIndex),
    oneDayDateLabels: dateLabel.slice(startIndex, endIndex),
    oneDayTimeLables: timeLable.slice(startIndex, endIndex),
    oneDayDayOnly: dayOnly.slice(startIndex, endIndex),

    // Weather data
    oneDayTemperature: temperature_2m.slice(startIndex, endIndex),
    oneDayPrecipitationProbability: precipitation_probability.slice(
      startIndex,
      endIndex
    ),
    oneDayPrecipitation: precipitation.slice(startIndex, endIndex),
    oneDayRain: rain.slice(startIndex, endIndex),
    oneDayCloudCover: cloud_cover.slice(startIndex, endIndex),
    oneDayWindSpeed: wind_speed_10m.slice(startIndex, endIndex),
    oneDayWindDirection: wind_direction_10m.slice(startIndex, endIndex),
    oneDayWindGusts: wind_gusts_10m.slice(startIndex, endIndex),

    // Marine data
    oneDayWaveHeight: wave_height.slice(startIndex, endIndex),
    oneDayWaveDirection: wave_direction.slice(startIndex, endIndex),
    oneDayWavePeriod: wave_period.slice(startIndex, endIndex),
    oneDayWindWaveHeight: wind_wave_height.slice(startIndex, endIndex),
    oneDaySwellWaveHeight: hasSwellData
      ? swell_wave_height.slice(startIndex, endIndex)
      : Array(endIndex - startIndex).fill(0),
    oneDaySwellWaveDirection: swell_wave_direction
      ? swell_wave_direction.slice(startIndex, endIndex)
      : null,
    oneDaySwellWavePeriod: swell_wave_period
      ? swell_wave_period.slice(startIndex, endIndex)
      : null,
  };
};

/**
 * Process time arrays directly from the API response
 * @param {Array} timeArray - Array of time strings
 * @returns {Object} Processed time data
 */
const getLocalTimesFromArray = (timeArray) => {
  const localTime = [];
  const dateLabel = [];
  const timeLable = [];
  const dayOnly = [];

  for (let i = 0; i < timeArray.length; i++) {
    const timeElement = getDateLabel(timeArray[i]);
    localTime.push(timeElement.date);
    dateLabel.push(timeElement.dateLabel);
    timeLable.push(timeElement.timeLable);
    dayOnly.push(timeElement.dayOnly);
  }

  return { localTime, dateLabel, timeLable, dayOnly };
};

const getLocalTimes = (data) => {
  const { time } = data.hourly;
  const localTime = [];
  const dateLabel = [];
  const timeLable = [];
  const dayOnly = [];

  for (let i = 0; i < time.length; i++) {
    const timeElemtent = getDateLabel(time[i]);
    localTime.push(timeElemtent.date);
    dateLabel.push(timeElemtent.dateLabel);
    timeLable.push(timeElemtent.timeLable);
    dayOnly.push(timeElemtent.dayOnly);
  }

  return { localTime, dateLabel, timeLable, dayOnly };
};

const getDateLabel = (dateString) => {
  const [datePart, timePart] = dateString.split('T');
  const [year, month, day] = datePart.split('-').map(Number);
  const [hour] = timePart.split(':').map(Number);
  const date = new Date(Date.UTC(year, month - 1, day, hour, 0, 0));
  const momentDate = moment(date);
  // const formattedDate = momentDate.format(
  //   hour >= 0 && hour <= 4 ? 'DD/MM HH:mm' : 'HH:mm'
  // );
  const formattedDate = momentDate.format('DD/MM HH:mm');
  const formattedHour = momentDate.format('HH:mm');

  return {
    date: date,
    dateLabel: formattedDate,
    timeLable: formattedHour,
    dayOnly: momentDate.format('DD/MM'),
  };
};

const getForecastSubset = (data) => {
  const {
    localTime,
    dateLabel,
    timeLable,
    dayOnly,
    wave_height,
    wave_direction,
    wave_period,
    wind_wave_height,
    wind_wave_period,
  } = data.hourly;
  const now = moment();
  const twentyFourHoursFromNow = moment().add(24, 'hours');

  let startIndex = null;
  let endIndex = null;

  for (let i = 0; i < localTime.length; i++) {
    const date = moment(localTime[i]);
    if (date.isSameOrAfter(now) && startIndex === null) {
      startIndex = i;
    }
    if (date.isSameOrAfter(twentyFourHoursFromNow)) {
      endIndex = i;
      break;
    }
  }

  const oneDayLocalTime = localTime.slice(startIndex, endIndex);
  const oneDayDateLabels = dateLabel.slice(startIndex, endIndex);
  const oneDayTimeLables = timeLable.slice(startIndex, endIndex);
  const oneDayDayOnly = dayOnly.slice(startIndex, endIndex);
  const oneDayWaveHeight = wave_height.slice(startIndex, endIndex);
  const oneDayWaveDirection = wave_direction.slice(startIndex, endIndex);
  const oneDayWavePeriod = wave_period.slice(startIndex, endIndex);
  const oneDayWindWaveHeight = wind_wave_height.slice(startIndex, endIndex);
  const oneDayWindWavePeriod = wind_wave_period.slice(startIndex, endIndex);

  return {
    oneDayLocalTime,
    oneDayDateLabels,
    oneDayTimeLables,
    oneDayDayOnly,
    oneDayWaveHeight,
    oneDayWaveDirection,
    oneDayWavePeriod,
    oneDayWindWaveHeight,
    oneDayWindWavePeriod,
  };
};

// const orgaizeWeatherForecast = (data) => {};
