// src/components/EditTrips/EditTrips.js
import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { FaGoogle } from 'react-icons/fa';
import EditTripsForm from './EditTripsForm';
import classes from './EditTrips.module.css';
import { isAllowed } from '../../api/tripsApi';
import { isSuccessResponse } from '../../api/apiUtils';
import { UserContext } from '../../contexts/UserContext';
import TheSpinner from '../utils/TheSpinner';

const GoogleLoginButton = ({ onClick }) => (
  <Button onClick={onClick} aria-label="Sign in with Google">
    Sign in with Google &nbsp;&nbsp;
    <FaGoogle />
  </Button>
);

const EditTrips = () => {
  const { user, login, logout } = useContext(UserContext);
  const [allow, setAllow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const auth = useCallback(async () => {
    if (!user || !user.access_token) return;

    setLoading(true);
    setError(null);

    try {
      const response = await isAllowed({ token: user.access_token });

      if (isSuccessResponse(response)) {
        setAllow(response.data && response.success);
      } else {
        console.error('Authentication error:', response.message);
        setError(
          response.message || 'Failed to authenticate. Please try again.'
        );
        logout(); // Logout user if authentication fails
      }
    } catch (err) {
      console.error('Auth error:', err);
      setError('An unexpected error occurred during authentication');
      logout(); // Logout user if authentication fails
    } finally {
      setLoading(false);
    }
  }, [user, logout]);

  useEffect(() => {
    if (user) {
      auth();
    } else {
      setAllow(false);
    }
  }, [user, auth]);

  if (loading) {
    return (
      <div className={classes.loading}>
        <TheSpinner />
        <p>Authenticating...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={classes.error}>
        <h3>Authentication Error</h3>
        <p>{error}</p>
        <Button variant="primary" onClick={login}>
          Try Again
        </Button>
      </div>
    );
  }

  return (
    <div className={classes.EditTrips}>
      <h2>Edit Trips</h2>
      {allow ? (
        <>
          <Button
            onClick={logout}
            variant="outline-danger"
            style={{ float: 'right' }}
          >
            Logout
          </Button>
          <EditTripsForm />
        </>
      ) : (
        <GoogleLoginButton onClick={login} />
      )}
    </div>
  );
};

export default EditTrips;
