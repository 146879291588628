import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { getGalleries } from '../api/tripsApi';
import { isSuccessResponse } from '../api/apiUtils';
import TheSpinner from './utils/TheSpinner';
import classes from './CardTitle.module.css';
import { MdOutlineKayaking } from 'react-icons/md';

const Galleries = () => {
  const [galleries, setGalleries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getAllGalleries = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await getGalleries();

        if (isSuccessResponse(response)) {
          setGalleries(response.data);
        } else {
          console.error('Error fetching galleries:', response.message);
          setError(response.message || 'Failed to load galleries');
        }
      } catch (error) {
        console.error('Error in getAllGalleries:', error);
        setError('An unexpected error occurred while loading galleries');
      } finally {
        setIsLoading(false);
      }
    };

    getAllGalleries();
  }, []);

  const GalleriesCards = ({ galleriesList }) =>
    galleriesList.map((gallery) => (
      <Col xs={12} sm={6} md={4} key={gallery.galleryId}>
        <div className={classes.CardTitle}>
          <div className={classes.Title}>{gallery.title}</div>
        </div>
        <Card border="light" style={{ marginBottom: '1rem' }}>
          <Link to={`${gallery.galleryId.toLowerCase()}`}>
            <Card.Img
              variant="top"
              src={
                gallery?.thumbnail ? (
                  `${process.env.REACT_APP_GCP_URL}/galleries/${gallery.thumbnail}`
                ) : (
                  <MdOutlineKayaking />
                )
              }
            />
          </Link>
        </Card>
      </Col>
    ));

  if (isLoading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '2rem' }}>
        <TheSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ textAlign: 'center', marginTop: '15px' }}>
        <h3>Error</h3>
        <p>{error}</p>
      </div>
    );
  }

  if (!galleries || galleries.length === 0) {
    return <div>No galleries available.</div>;
  }

  return (
    <>
      <h2>Pictures</h2>
      <GalleriesCards galleriesList={galleries} />
    </>
  );
};

export default Galleries;
