import { api, cacheKey, getCachedData, setCachedData } from './Api';
import { handleError, formatResponse, isSuccessResponse } from './apiUtils';

// Cache expiry times in minutes
const CACHE_EXPIRY = {
  TRIPS: 20160, // 2 weeks
  POINTS: 1440, // 1 day
  GALLERIES: 20160, // 2 weeks
  PICTURES: 20160, // 2 weeks
};

/**
 * Get all trips
 * @returns {Promise<Object>} Trips data or error response
 */
export const getTrips = async () => {
  try {
    // Check cache first
    const cacheKeyString = cacheKey('trips');
    const cachedData = getCachedData(cacheKeyString, CACHE_EXPIRY.TRIPS);

    if (cachedData) {
      console.log('Using cached trips data');
      return formatResponse(cachedData);
    }

    // If not in cache, fetch from API
    const response = await api.get('/trips');

    if (!response || !response.data) {
      throw new Error('Invalid response from trips API');
    }

    // Cache the data
    setCachedData(cacheKeyString, response.data);

    return formatResponse(response.data);
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Get points for a specific trip
 * @param {Object} payload - The request payload
 * @param {string} payload.tripId - The trip ID
 * @returns {Promise<Object>} Points data or error response
 */
export const getPoints = async (payload) => {
  try {
    if (!payload.tripId) {
      throw new Error('Trip ID is required');
    }

    // Check cache first
    const cacheKeyString = cacheKey(`points_${payload.tripId}`);
    const cachedData = getCachedData(cacheKeyString, CACHE_EXPIRY.POINTS);

    if (cachedData) {
      console.log(`Using cached points data for trip ${payload.tripId}`);
      return formatResponse(cachedData);
    }

    // If not in cache, fetch from API
    const response = await api.get(`/trips/${payload.tripId}`);

    if (!response || !response.data) {
      throw new Error('Invalid response from points API');
    }

    // Cache the data
    setCachedData(cacheKeyString, response.data);

    return formatResponse(response.data);
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Get all galleries
 * @returns {Promise<Object>} Galleries data or error response
 */
export const getGalleries = async () => {
  try {
    // Check cache first
    const cacheKeyString = cacheKey('galleries');
    const cachedData = getCachedData(cacheKeyString, CACHE_EXPIRY.GALLERIES);

    if (cachedData) {
      console.log('Using cached galleries data');
      return formatResponse(cachedData);
    }

    // If not in cache, fetch from API
    const response = await api.get('/galleries');

    if (!response || !response.data) {
      throw new Error('Invalid response from galleries API');
    }

    // Cache the data
    setCachedData(cacheKeyString, response.data);

    return formatResponse(response.data);
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Get pictures for a specific gallery
 * @param {Object} payload - The request payload
 * @param {string} payload.galleryId - The gallery ID
 * @returns {Promise<Object>} Pictures data or error response
 */
export const getPictures = async (payload) => {
  try {
    if (!payload.galleryId) {
      throw new Error('Gallery ID is required');
    }

    // Check cache first
    const cacheKeyString = cacheKey(`pictures_${payload.galleryId}`);
    const cachedData = getCachedData(cacheKeyString, CACHE_EXPIRY.PICTURES);

    if (cachedData) {
      console.log(
        `Using cached pictures data for gallery ${payload.galleryId}`
      );
      return formatResponse(cachedData);
    }

    // If not in cache, fetch from API
    const response = await api.get(`/galleries/${payload.galleryId}`);

    if (!response || !response.data) {
      throw new Error('Invalid response from pictures API');
    }

    // Cache the data
    setCachedData(cacheKeyString, response.data);

    return formatResponse(response.data);
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Save or update a point
 * @param {Object} payload - The point data
 * @param {string} pointId - Optional point ID for updates
 * @returns {Promise<Object>} Success or error response
 */
export const savePoint = async (payload, pointId) => {
  try {
    const data = {
      tripId: payload.tripId,
      pointNumber: Number(payload.pointNumber),
      pointName: payload.pointName,
      longitude: Number(payload.longitude),
      latitude: Number(payload.latitude),
      paddleDirection: payload.paddleDirection,
      distanceNoticalMiles: Number(payload.distanceNoticalMiles),
      date: payload.date,
      windDirection: payload.windDirection,
      windStrength: Number(payload.windStrength),
      freeText: payload.freeText,
      image: payload.image,
    };

    let response;

    if (pointId) {
      console.log('Updating point', pointId);
      response = await api.put(`/point/${pointId}`, data);
    } else {
      console.log('Creating new point');
      response = await api.post(`/point`, data);
    }

    // Invalidate cache for this trip's points
    if (payload.tripId) {
      localStorage.removeItem(cacheKey(`points_${payload.tripId}`));
    }

    return formatResponse({
      success: true,
      message: pointId
        ? 'Point updated successfully'
        : 'Point created successfully',
      data: response.data,
    });
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Verify user authentication
 * @param {Object} payload - The auth payload
 * @param {string} payload.token - The auth token
 * @returns {Promise<Object>} Auth verification result or error response
 */
export const isAllowed = async (payload) => {
  try {
    if (!payload.token) {
      throw new Error('Token is required');
    }

    const response = await api.post(
      `/auth/verify`,
      {},
      {
        headers: { Authorization: `Bearer ${payload.token}` },
      }
    );

    if (!response || response.status !== 200) {
      return formatResponse({
        success: false,
        message: 'Authentication failed',
      });
    }

    if (
      response?.data?.message === 'Authorized' &&
      response?.data?.accessToken
    ) {
      localStorage.setItem('accessToken', response.data.accessToken);

      return formatResponse({
        success: true,
        message: 'Authentication successful',
        data: { accessToken: response.data.accessToken },
      });
    }

    return formatResponse({
      success: false,
      message: 'Authentication failed',
    });
  } catch (error) {
    return handleError(error);
  }
};
