/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaLinkedin, FaEnvelope, FaPhone, FaGlobe } from 'react-icons/fa';
import { Link } from 'react-router-dom';
const Dev = () => {
  const skills = {
    management: [
      'Tech Team Leadership',
      'On-Time Delivery',
      'Project Design & Build',
      'Multi-disciplinary Team Leadership',
      'Offshore Team Management',
      'Startup Environment Navigation',
      'Complex Product Delivery',
      'Remote-first Culture Building',
    ],
    technical: [
      'NodeJS',
      'Python',
      'GO',
      'ReactJS',
      'React Native',
      'MongoDB',
      'Docker',
      'Kubernetes',
      'AWS',
      'Big Data',
      'Google Cloud',
      'Linux',
      'PostgreSQL',
      'MySQL',
      'nginx',
      'Redis',
    ],
    soft: [
      'Team Leadership',
      'Project Management',
      'Cross-functional Collaboration',
      'Problem Solving',
      'Technical Architecture',
    ],
  };

  // const journeyHighlights = {
  //   tech: [
  //     'Built systems that process millions of data points daily',
  //     'Architected solutions that scale across continents',
  //     'Transformed complex algorithms into user-friendly applications',
  //     'Pioneered remote-first development culture during COVID',
  //   ],
  //   leadership: [
  //     'Mentored dozens of developers across three continents',
  //     'Built bridges between technical teams and business stakeholders',
  //     'Turned challenging project requirements into successful deliveries',
  //     'Created inclusive, high-performing team cultures',
  //   ],
  // };

  const getContactInfo = () => {
    const emailParts = ['alonohad', 'gmail.com'];
    const phoneParts = ['972', '52', '349', '4508'];

    return {
      email: `${emailParts[0]}@${emailParts[1]}`,
      phone: `+${phoneParts[0]}${phoneParts[1]}${phoneParts[2]}${phoneParts[3]}`,
    };
  };

  const handleEmailClick = (e) => {
    e.preventDefault();
    const { email } = getContactInfo();
    window.location.href = `mailto:${email}`;
  };

  const handlePhoneClick = (e) => {
    e.preventDefault();
    const { phone } = getContactInfo();
    window.location.href = `tel:${phone}`;
  };

  return (
    <Container className="mt-4">
      {/* Header/Contact Info */}
      <Row className="mb-4">
        <Col>
          <h1>Alon Ohad</h1>
          <h3 className="text-muted">Squad / Team Manager</h3>
          <div className="d-flex gap-3 mt-3">
            <a href="#" onClick={handleEmailClick} className="text-dark">
              <FaEnvelope size={20} />
            </a>
            <a href="#" onClick={handlePhoneClick} className="text-dark">
              <FaPhone size={20} />
            </a>
            <a
              href="https://linkedin.com/in/alon-ohad"
              className="text-dark"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin size={20} />
            </a>
            <Link to="/" className="text-dark">
              <FaGlobe size={20} />
            </Link>
          </div>
        </Col>
      </Row>

      {/* Skills Section - Moved to top */}
      <Row className="mb-4">
        <Col>
          <Card className="p-4">
            <h4 className="mb-3">Skills & Expertise</h4>

            <h5 className="text-muted mb-2">Technical Stack</h5>
            <div className="d-flex flex-wrap gap-2 mb-4">
              {skills.technical.map((skill, index) => (
                <span key={index} className="badge bg-primary">
                  {skill}
                </span>
              ))}
            </div>

            <h5 className="text-muted mb-2">Management</h5>
            <div className="d-flex flex-wrap gap-2 mb-4">
              {skills.management.map((skill, index) => (
                <span key={index} className="badge bg-success">
                  {skill}
                </span>
              ))}
            </div>

            <h5 className="text-muted mb-2">Core Competencies</h5>
            <div className="d-flex flex-wrap gap-2">
              {skills.soft.map((skill, index) => (
                <span key={index} className="badge bg-secondary">
                  {skill}
                </span>
              ))}
            </div>
          </Card>
        </Col>
      </Row>

      {/* Professional Summary */}
      <Row className="mb-4">
        <Col>
          <Card className="p-4">
            <h4>Professional Summary</h4>
            <p>
              I love building things with code, and what really gets me excited
              is leading and inspiring development teams while staying hands-on
              with the technology. Over the years, I've found that sweet spot
              between writing code and guiding teams that create software used
              by millions of people. There's nothing quite like the feeling of
              taking a tough problem and turning it into something that just
              works - whether that's helping doctors do their jobs better or
              making machines run smoother.
            </p>
            <p>
              Just like when I'm out on the water in my kayak, I help my teams
              navigate through the ups and downs of tech projects. What keeps me
              going is diving into code alongside my team members, seeing our
              ideas come to life, and knowing our work makes things better for
              the people who use it.
            </p>
          </Card>
        </Col>
      </Row>

      {/* My Journey */}
      <Row className="mb-4">
        <Col>
          <Card className="p-4">
            <h4>My Journey</h4>
            <p>
              I'm a tech explorer who's been navigating the waters of software
              development for over two decades. Just like my sea kayaking
              expeditions, my technical journey has been about embracing
              challenges, leading teams through uncharted territories, and
              discovering innovative solutions.
            </p>
            <p>
              From building AI healthcare solutions to creating systems that can
              hear machines whisper their problems, I've always sought projects
              that make a real difference in people's lives. My approach
              combines technical expertise with the same resilience and
              adaptability I've learned from facing ocean swells in my kayak.
            </p>
          </Card>
        </Col>
      </Row>

      {/* Where Technology Meets Adventure */}
      <Row className="mb-4">
        <Col>
          <Card className="p-4">
            <h4>Where Technology Meets Adventure</h4>
            <p>
              When I need to clear my head from complex code architectures and
              team challenges, I head to the ocean. But like everything else in
              my life, what started as an escape turned into a pursuit of
              excellence. For the past 24 years, I've been pushing my limits as
              a professional sea kayaking guide, leading expeditions through
              some of the world's most challenging waters - from the rugged
              coastlines of Tasmania to the icy waters of Svalbard.
            </p>
            <p>
              It's funny how my two passions mirror each other. In tech, I push
              the boundaries of what's possible with code and leadership. On the
              water, I challenge myself against nature's most demanding
              conditions. Both require the same mix of careful planning, quick
              thinking, and the courage to venture into uncharted territory.
              Whether I'm debugging a complex system or navigating through rough
              seas, it's about staying calm under pressure and making smart
              decisions when it matters most.
            </p>
          </Card>
        </Col>
      </Row>

      {/* <Row className="mb-4">
        <Col md={6}>
          <Card className="p-4 h-100">
            <h4>Technical Expeditions</h4>
            <ul className="list-unstyled">
              {journeyHighlights.tech.map((achievement, index) => (
                <li key={index} className="mb-2">
                  • {achievement}
                </li>
              ))}
            </ul>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="p-4 h-100">
            <h4>Leadership Adventures</h4>
            <ul className="list-unstyled">
              {journeyHighlights.leadership.map((achievement, index) => (
                <li key={index} className="mb-2">
                  • {achievement}
                </li>
              ))}
            </ul>
          </Card>
        </Col>
      </Row> */}
    </Container>
  );
};

export default Dev;
