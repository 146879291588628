import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { getTrips } from '../api/tripsApi';
import { isSuccessResponse } from '../api/apiUtils';
import TheSpinner from './utils/TheSpinner';
import classes from './CardTitle.module.css';

const ExpeditionSelector = () => {
  const [trips, setTripsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getAllTrips = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await getTrips();

        if (isSuccessResponse(response)) {
          setTripsList(response.data);
        } else {
          console.error('Error fetching trips:', response.message);
          setError(response.message || 'Failed to load expeditions');
        }
      } catch (error) {
        console.error('Error in getAllTrips:', error);
        setError('An unexpected error occurred while loading expeditions');
      } finally {
        setIsLoading(false);
      }
    };

    getAllTrips();
  }, []);

  const svg = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="-10 -5 44 44" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M21 23c-1.03 0-2.06-.25-3-.75-1.89 1-4.11 1-6 0-1.89 1-4.11 1-6 0-.95.5-1.97.75-3 .75H2v-2h1c1.04 0 2.08-.35 3-1 1.83 1.3 4.17 1.3 6 0 1.83 1.3 4.17 1.3 6 0 .91.65 1.96 1 3 1h1v2h-1zM12 5.5c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 12s-1.52.71-3.93 1.37c-.82-.23-1.53-.75-2.07-1.37-.73.84-1.8 1.5-3 1.5s-2.27-.66-3-1.5c-.73.84-1.8 1.5-3 1.5s-2.27-.66-3-1.5c-.54.61-1.25 1.13-2.07 1.37C1.52 18.21 0 17.5 0 17.5s2.93-1.36 7.13-2.08l1.35-4.17c.31-.95 1.32-1.47 2.27-1.16.09.03.19.07.27.11l2.47 1.3 2.84-1.5 1.65-3.71-.51-1.32L18.8 2 22 3.43 20.67 6.4l-1.31.5-3.72 8.34c4.85.63 8.36 2.26 8.36 2.26zm-8.98-4.54l-1.52.8-1.75-.92-.71 2.17c.32 0 .64-.01.96-.01.71 0 1.4.03 2.07.08l.95-2.12z"></path></svg>`;

  const TripCards = () =>
    trips.map((trip) => (
      <Col xs={12} sm={6} md={4} key={trip.tripName}>
        <div>
          <div className={classes.CardTitle}>
            <div className={classes.Title}>{trip.tripName}</div>
          </div>
          <Card
            border="light"
            key={trips._id}
            className="text-center"
            style={{ marginBottom: '1rem' }}
          >
            <Link
              to={trip.tripId.toLowerCase()}
              className={classes.Link}
              state={{ status: trip.status }}
            >
              {' '}
              <Card.Img
                variant="top"
                src={
                  trip.image
                    ? `${process.env.REACT_APP_GCP_URL}/exp/${trip.image}`
                    : `data:image/svg+xml,${svg}`
                }
              />
            </Link>
          </Card>
        </div>
      </Col>
    ));

  if (isLoading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '2rem' }}>
        <TheSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ textAlign: 'center', marginTop: '15px' }}>
        <h3>Error</h3>
        <p>{error}</p>
      </div>
    );
  }

  if (!trips || trips.length === 0) {
    return (
      <div style={{ textAlign: 'center', marginTop: '15px' }}>
        <p>No expeditions available</p>
      </div>
    );
  }

  return (
    <>
      <h2>Expeditions</h2>
      <TripCards />
    </>
  );
};

export default ExpeditionSelector;
