/**
 * Standardized API error handler
 * @param {Error} error - The error object from axios
 * @returns {Object} Standardized error response
 */
export const handleError = (error) => {
  // Authentication errors
  if (error?.response?.status === 401 || error?.response?.status === 403) {
    return {
      success: false,
      status: error.response.status,
      message: 'Authentication error. Please log in again.',
      error: error.response.data,
    };
  }

  // Server returned an error response
  if (error.response) {
    return {
      success: false,
      status: error.response.status,
      message: error.response.data?.message || 'Server error',
      error: error.response.data,
    };
  }

  // Request was made but no response received
  if (error.request) {
    return {
      success: false,
      status: 'NETWORK_ERROR',
      message: 'No response from server. Please check your connection.',
      error: error.request,
    };
  }

  // Error in setting up the request
  return {
    success: false,
    status: 'REQUEST_ERROR',
    message: error.message || 'An unknown error occurred',
    error: error,
  };
};

/**
 * Format successful API responses
 * @param {Object} data - The response data
 * @returns {Object} Standardized success response
 */
export const formatResponse = (data) => {
  return {
    success: true,
    data,
  };
};

/**
 * Check if response indicates success
 * @param {Object} response - The response object
 * @returns {boolean} Whether the response indicates success
 */
export const isSuccessResponse = (response) => {
  return response && response.success === true;
};
