import { useEffect, useState } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
// import classNames from 'classnames';
import { getPoints, getTrips } from '../api/tripsApi';
import { isSuccessResponse } from '../api/apiUtils';
import MapPoints from './map/MapPoints';
import TheSpinner from './utils/TheSpinner';
import LeafletMap from './map/LeafletMap';
import classes from './Expeditions.module.css';

const Expeditions = () => {
  const { tripId } = useParams();
  const [points, setPoints] = useState([]);
  const [pageTitle, setPageTitle] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const location = useLocation();
  const className = 'btn btn-secondary btn-lg btn-block ' + classes.PicButton;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        if (tripId) {
          const pointsResponse = await getPoints({ tripId });

          if (isSuccessResponse(pointsResponse)) {
            setPoints(pointsResponse.data);
          } else {
            console.error('Error fetching points:', pointsResponse.message);
            setError(
              pointsResponse.message || 'Failed to load expedition data'
            );
            setLoading(false);
            return;
          }
        }

        const tripsResponse = await getTrips();

        if (isSuccessResponse(tripsResponse)) {
          const trips = tripsResponse.data;
          const trip = trips.find(
            (t) => t.tripId.toLowerCase() === tripId.toLowerCase()
          );

          if (trip) {
            setPageTitle(trip.tripName);
          } else {
            setPageTitle(tripId.charAt(0).toUpperCase() + tripId.slice(1));
          }
        } else {
          console.error('Error fetching trips:', tripsResponse.message);
          setPageTitle(tripId.charAt(0).toUpperCase() + tripId.slice(1));
        }
      } catch (error) {
        console.error('Error in fetchData:', error);
        setError('An unexpected error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [tripId, location?.state?.status]);

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '15px' }}>
        <TheSpinner size="sm" />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ textAlign: 'center', marginTop: '15px' }}>
        <h3>Error</h3>
        <p>{error}</p>
      </div>
    );
  }

  if (!tripId || points.length === 0) {
    return (
      <div style={{ textAlign: 'center', marginTop: '15px' }}>
        <p>No expedition data found</p>
      </div>
    );
  }

  return (
    <>
      <h2>{pageTitle}</h2>
      <LeafletMap points={points} />
      <Link
        to={`/pictures/${tripId === 'faroe' ? 'faroes' : tripId}`}
        className={className}
      >
        <span style={{ textTransform: 'capitalize' }}>
          {pageTitle} Pictures
        </span>
      </Link>

      <MapPoints
        points={points}
        style={{
          transition: 'opacity 0.5s ease-in-out',
          opacity: 1,
        }}
      />
    </>
  );
};

export default Expeditions;
