import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  ZoomControl,
  LayersControl,
  useMap,
} from 'react-leaflet';
import { BsFullscreen, BsFullscreenExit } from 'react-icons/bs';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import Modal from 'react-bootstrap/Modal';
import './LeafletMap.css';
import './SurfSpotsMap.css';
import { MdWaves } from 'react-icons/md';
import { FaWater } from 'react-icons/fa';
import { getSurfSpots } from '../../api/forecastApi';
import { isSuccessResponse } from '../../api/apiUtils';
import TheSpinner from '../utils/TheSpinner';
import { renderToStaticMarkup } from 'react-dom/server';

// Fix Leaflet default icon issue
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

L.Marker.prototype.options.icon = DefaultIcon;

const { BaseLayer } = LayersControl;

// Custom marker icon for surf spots
const surfMarkerIcon = L.divIcon({
  html: renderToStaticMarkup(
    <div className="surf-marker-icon">
      <MdWaves size={24} color="#0066cc" />
    </div>
  ),
  className: 'surf-marker',
  iconSize: [24, 24],
  iconAnchor: [12, 12],
  popupAnchor: [0, -12],
});

const MapBounds = ({ points }) => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    // Default to Tel Aviv if no points
    if (!points || points.length === 0) {
      map.setView([32.0853, 34.7818], 8);
      return;
    }

    try {
      // Filter valid points
      const validPoints = points.filter((point) => {
        // Check if point has location with coordinates
        if (
          !point ||
          !point.location ||
          !point.location.coordinates ||
          !Array.isArray(point.location.coordinates) ||
          point.location.coordinates.length !== 2
        ) {
          return false;
        }

        // In GeoJSON format, coordinates are [longitude, latitude]
        const longitude = point.location.coordinates[0];
        const latitude = point.location.coordinates[1];

        return (
          typeof latitude === 'number' &&
          typeof longitude === 'number' &&
          !isNaN(latitude) &&
          !isNaN(longitude) &&
          Math.abs(latitude) <= 90 &&
          Math.abs(longitude) <= 180
        );
      });

      if (validPoints.length === 0) {
        // No valid points, default to Tel Aviv
        map.setView([32.0853, 34.7818], 8);
        return;
      }

      if (validPoints.length === 1) {
        // Only one point, center on it
        const point = validPoints[0];
        const longitude = point.location.coordinates[0];
        const latitude = point.location.coordinates[1];
        map.setView([latitude, longitude], 10);
        return;
      }

      // Create array of LatLng objects for valid points
      const latLngs = validPoints.map((point) => {
        const longitude = point.location.coordinates[0];
        const latitude = point.location.coordinates[1];
        return L.latLng(latitude, longitude);
      });

      // Create bounds from array of points
      const bounds = L.latLngBounds(latLngs);

      // Check if bounds are valid before fitting
      if (bounds && bounds.isValid()) {
        map.fitBounds(bounds, { padding: [50, 50] });
      } else {
        // Fallback to default view
        map.setView([32.0853, 34.7818], 8);
      }
    } catch (error) {
      console.error('Error setting map bounds:', error);
      // Fallback to default view on error
      map.setView([32.0853, 34.7818], 8);
    }
  }, [map, points]);

  return null;
};

const FullscreenControl = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const map = useMap();

  const toggleFullscreen = useCallback(() => {
    if (!isFullscreen) {
      // Request fullscreen mode
      const mapElement = map.getContainer();
      if (mapElement.requestFullscreen) {
        mapElement.requestFullscreen();
      } else if (mapElement.webkitRequestFullscreen) {
        mapElement.webkitRequestFullscreen();
      } else if (mapElement.msRequestFullscreen) {
        mapElement.msRequestFullscreen();
      }
    } else {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen);
  }, [isFullscreen, map]);

  return (
    <div className="leaflet-control leaflet-bar">
      <div onClick={toggleFullscreen} className="fullscreen-control">
        {isFullscreen ? (
          <BsFullscreenExit size={20} />
        ) : (
          <BsFullscreen size={20} />
        )}
      </div>
    </div>
  );
};

// Sample data for testing if API returns empty
const sampleSurfSpots = [
  {
    id: 'sdot_yam',
    name: 'Sdot Yam',
    location: {
      coordinates: [34.8867, 32.4935], // [longitude, latitude] in GeoJSON format
    },
    description: 'Popular surf spot near Caesarea',
  },
  {
    id: 'tlv_yarkon',
    name: 'Tel Aviv - Hayarkon',
    location: {
      coordinates: [34.7818, 32.0853], // [longitude, latitude] in GeoJSON format
    },
    description: 'Urban surf spot in Tel Aviv',
  },
];

const SurfSpotsMap = ({ show, onHide, surfSpots: injectedSurfSpots }) => {
  const [surfSpots, setSurfSpots] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSpot, setSelectedSpot] = useState(null);
  const mapRef = useRef(null);
  const [mapReady, setMapReady] = useState(false);
  const mapRemovedRef = useRef(false);
  const [shouldRenderMap, setShouldRenderMap] = useState(false);

  // Generate a new key whenever the modal is shown
  const mapKeyRef = useRef(`surfspots-initial-${Date.now()}`);

  // Update the key when show changes and control map rendering
  useEffect(() => {
    if (show) {
      mapKeyRef.current = `surfspots-${Date.now()}`;
      mapRemovedRef.current = false;
      // Delay rendering the map to ensure clean mount
      setTimeout(() => {
        setShouldRenderMap(true);
      }, 100);
    } else {
      // Immediately unmount the map when modal is closed
      setShouldRenderMap(false);
    }
  }, [show]);

  // Fetch surf spots when modal is shown
  useEffect(() => {
    let isMounted = true;

    const fetchSurfSpots = async () => {
      if (!show) return;

      // If we have injected surf spots, use those instead of fetching
      if (injectedSurfSpots && injectedSurfSpots.length > 0) {
        setSurfSpots(injectedSurfSpots);
        setLoading(false);
        return;
      }

      // Only fetch if we don't already have data
      if (surfSpots.length > 0 && !loading && !error) {
        return;
      }

      setLoading(true);
      setError(null);

      try {
        // Use the existing API function to get surf spots
        const response = await getSurfSpots();

        if (!isMounted) return;

        if (isSuccessResponse(response)) {
          if (Array.isArray(response.data) && response.data.length > 0) {
            setSurfSpots(response.data);
          } else {
            console.warn('API returned empty or invalid surf spots data');
            setSurfSpots(sampleSurfSpots);
          }
        } else {
          console.error('Error fetching surf spots:', response.message);
          setError(response.message || 'Failed to load surf spots data');
          setSurfSpots(sampleSurfSpots);
        }
      } catch (error) {
        if (!isMounted) return;
        console.error('Error in fetchSurfSpots:', error);
        setError('An unexpected error occurred');
        setSurfSpots(sampleSurfSpots);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    if (show) {
      fetchSurfSpots();
    } else {
      // Reset state when modal is closed
      setSelectedSpot(null);
      setMapReady(false);

      // Ensure map is unmounted when modal is closed
      setShouldRenderMap(false);
    }

    return () => {
      isMounted = false;
    };
  }, [show, surfSpots.length, loading, error, injectedSurfSpots]);

  // Separate useEffect for map cleanup to avoid dependency issues
  useEffect(() => {
    return () => {
      // Global cleanup when component unmounts
      if (mapRef.current && !mapRemovedRef.current) {
        try {
          if (
            mapRef.current &&
            mapRef.current._container &&
            document.body.contains(mapRef.current._container)
          ) {
            mapRef.current.remove();
          }
        } catch (error) {
          console.error('Error during final map cleanup:', error);
        } finally {
          mapRef.current = null;
          mapRemovedRef.current = true;
        }
      }
    };
  }, []);

  // Add this function to store map reference
  const onMapCreate = useCallback((map) => {
    if (map) {
      // If there was a previous map instance, try to clean it up
      if (mapRef.current && mapRef.current !== map) {
        try {
          if (
            mapRef.current._container &&
            document.body.contains(mapRef.current._container)
          ) {
            mapRef.current.remove();
          }
        } catch (error) {
          console.error('Error removing previous map:', error);
        }
      }

      mapRef.current = map;
      mapRemovedRef.current = false;
      setMapReady(true);
    }
  }, []);

  // Function to handle forecast fetching when title is clicked
  const handleForecastFetch = useCallback(
    (spot) => {
      const { location, name } = spot;
      const longitude = location.coordinates[0];
      const latitude = location.coordinates[1];

      // Close the modal
      setShouldRenderMap(false);
      setTimeout(() => {
        // Pass the forecast data to the parent component
        onHide({
          action: 'fetchForecast',
          data: {
            latitude,
            longitude,
            name,
          },
        });
      }, 50);
    },
    [onHide]
  );

  // Create markers directly in the render function
  const renderMarkers = () => {
    return surfSpots.map((spot) => {
      const { location, name, id } = spot;
      const longitude = location.coordinates[0];
      const latitude = location.coordinates[1];

      if (
        !isNaN(latitude) &&
        !isNaN(longitude) &&
        Math.abs(latitude) <= 90 &&
        Math.abs(longitude) <= 180
      ) {
        return (
          <Marker
            key={id || name || `marker-${longitude}-${latitude}`}
            position={[latitude, longitude]}
            icon={surfMarkerIcon}
            eventHandlers={{
              click: () => setSelectedSpot(spot),
            }}
          >
            <Popup>
              <div className="surf-spot-popup">
                <div className="surf-spot-header">
                  <MdWaves size={20} color="#0066cc" />
                  <h3
                    className="clickable-title"
                    onClick={() => handleForecastFetch(spot)}
                  >
                    {name}
                  </h3>
                </div>
                {spot.description && <p>{spot.description}</p>}
                <div className="popup-footer">
                  <button
                    className="forecast-button"
                    onClick={() => handleForecastFetch(spot)}
                  >
                    <FaWater size={14} />
                    Get Forecast
                  </button>
                </div>
              </div>
            </Popup>
          </Marker>
        );
      }
      return null;
    });
  };

  // Component unmount cleanup
  useEffect(() => {
    return () => {
      // This will run when the entire component is unmounted
      if (mapRef.current) {
        try {
          if (
            mapRef.current &&
            mapRef.current._container &&
            document.body.contains(mapRef.current._container)
          ) {
            mapRef.current.remove();
          }
        } catch (error) {
          console.error('Error during component unmount cleanup:', error);
        } finally {
          mapRef.current = null;
        }
      }
    };
  }, []);

  if (!show) {
    return null;
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        // First unmount the map, then call onHide
        setShouldRenderMap(false);
        setTimeout(() => {
          onHide();
        }, 50);
      }}
      centered
      size="lg"
      className="surf-spots-modal"
      dialogClassName="surf-spots-modal-dialog"
    >
      <Modal.Header closeButton>
        <Modal.Title>Surf Spots</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div style={{ textAlign: 'center', padding: '2rem' }}>
            <TheSpinner />
          </div>
        ) : error && surfSpots.length === 0 ? (
          <div style={{ textAlign: 'center', padding: '2rem' }}>
            <h3>Error</h3>
            <p>{error}</p>
          </div>
        ) : (
          <div className="surf-spots-map-container">
            {shouldRenderMap && (
              <MapContainer
                key={mapKeyRef.current}
                center={[32.0853, 34.7818]} // Default center (Tel Aviv)
                zoom={8}
                style={{ height: '500px', width: '100%' }}
                zoomControl={false}
                ref={onMapCreate}
              >
                <LayersControl position="bottomright">
                  <BaseLayer checked name="OpenStreetMap">
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                  </BaseLayer>
                  <BaseLayer name="Satellite">
                    <TileLayer
                      attribution='&copy; <a href="https://www.esri.com/en-us/home">Esri</a>'
                      url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                    />
                  </BaseLayer>
                </LayersControl>

                {renderMarkers()}

                {mapReady && <MapBounds points={surfSpots} />}
                <ZoomControl position="topright" />
                <FullscreenControl />
              </MapContainer>
            )}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SurfSpotsMap;
