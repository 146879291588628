import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';

// const ACTIVE = 1;
// const POSTPONED = 0;

const Sponsors = () => {
  const listOfSponsors = [
    {
      sponsor: 'Blue Dog Kayaking',
      logo: 'Blue-Dog-Logo.svg',
      description:
        // "I'd like to express my sincere gratitude to Blue Dog Kayaking for their invaluable support as I prepare for my upcoming summer 2024 expedition in BC, Canada. Their mission to make sea kayaking accessible for all paddler levels, along with their experienced team of coaches, promises a memorable adventure. The dedication to safety, quality equipment, and educational opportunities like Paddle Canada courses offered by Blue Dog Kayaking has been truly remarkable. Their personalized service and commitment to fostering paddling skills are greatly appreciated. I'm looking forward to my first adventure with Blue Dog Kayaking and the opportunity to explore BC, Canada in the summer of 2024.",
        "I'm grateful to Blue Dog Kayaking for their support as I prepare for my first adventure in BC, Canada next summer. Their commitment to safety, quality equipment, and paddling education is truly impressive. Excited for what lies ahead!",
      website: 'https://bluedogkayaking.com/',
      bg: '',
      status: 1,
    },
    {
      sponsor: 'Kokatat',
      logo: 'Logo_kokatat.svg',
      description:
        "A few months into my paddling journey, I discovered Kokatat gear and realized they craft the finest quality gear for paddlers. They've been a steadfast supporter throughout some of my most challenging expeditions.",
      website: 'https://kokatat.com',
      bg: '',
      status: 1,
    },
    {
      sponsor: 'Sea Kayaking UK',
      logo: 'skuk_logo.jpg',
      description:
        "Sea Kayaking UK, formerly known as NDK, has been my steadfast sponsor over the years. Nigel's kayaks are renowned for their exceptional performance in demanding, challenging conditions. The expeditions that have relied on these kayaks in the past have shown that they can withstand whatever challenges the sea presents.",
      website: 'https://seakayakinguk.com/paddlers/alon-ohad',
      bg: '',
      status: 1,
    },
    {
      sponsor: 'Alfa Fritid',
      logo: 'alfa_fritid_logo.png',
      description:
        "Based in Tromsø, Alf has been an incredible source of support throughout my journey, and I'm immensely grateful to him. Alf specializes in selling and renting top-quality sea kayaking gear, and if you ever plan to explore the waters of North Norway, I highly recommend reaching out to him and visiting his shop!",
      website: 'https://www.alfafritid.no/',
      status: 1,
    },
    {
      sponsor: 'Drytech',
      logo: 'REAL_TM.png',
      description:
        "Real Turmat is hands down the best outdoor food I've relied on during all of my expeditions. Drytech was a pivotal supporter in my Svalbard Expedition.",
      website: 'https://realoutdoorfood.com',
      bg: 'rgba(0,0,0,0.3)',
      status: 1,
    },
    {
      sponsor: 'Lendal',
      logo: 'lendal.gif',
      description:
        "Back in its prime, Lendal stood as a beacon in the Scottish paddling community, supporting enthusiasts like myself through sponsorship. Though the company has since undergone changes, with its assets divided between the US and UK, the memories of those strong Lendal paddles endure. Today, under the banner of Celtic Paddles, guided by the expertise of Nigel Dennis, the spirit of quality and reliability that defined Lendal lives on, reminding us of the journeys we've shared with those trusted paddles in hand.",
      website: 'https://shop.seakayakinguk.co.uk/collections/paddles',
      bg: 'rgba(0,0,0,0.3)',
      status: 1,
    },
    {
      sponsor: "Arc'teryx",
      logo: 'arcteryx_logo.svg',
      description:
        "ARC'TERYX, driven by innovation, was founded to create exceptional gear, pioneering cutting-edge technology and design. They've been instrumental in my journeys, and I'm deeply grateful for their support.",
      website: 'https://arcteryx.com',
      bg: '',
      status: 1,
    },
    {
      sponsor: 'EXPED',
      logo: 'exped_logo.svg',
      description:
        'Exped, a German outdoor equipment company, has been a valuable partner in my past expeditions. I find immense comfort in their legendary mattresses, making every night a pleasure.',
      website: 'https://exped.com/en/homepage',
      bg: '',
      status: 1,
    },
  ];

  return (
    <>
      <h2>Sponsors</h2>
      {listOfSponsors.map((sponsor) => (
        <Col xs={12} sm={6} md={4} key={sponsor.sponsor}>
          <Card border="light" style={{ marginBottom: '1rem' }}>
            <a href={sponsor.website} target="_blank" rel="noreferrer">
              <Card.Img
                variant="top"
                src={`${process.env.REACT_APP_GCP_SITE_IMAGES_URL}/${sponsor.logo}`}
                style={{
                  display: 'flex',
                  maxHeight: '6rem',
                  alignSelf: 'center',
                  backgroundColor: sponsor.bg ? sponsor.bg : '',
                  padding: '4px',
                }}
              />
            </a>
            <Card.Body>
              <Card.Title>
                <a href={sponsor.website} target="_blank" rel="noreferrer">
                  {sponsor.sponsor}
                </a>
              </Card.Title>
              <Card.Text>{sponsor.description}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </>
  );
};

export default Sponsors;
