import { useEffect, useState, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';

// Import CSS with ?raw to skip source map processing
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

import lgThumbnail from 'lightgallery/plugins/thumbnail';

// import { LightGallerySettings } from 'lightgallery/lg-settings';
import TheSpinner from '../utils/TheSpinner';

const TheGallery = ({ images }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Move dimension calculations to useMemo
  const dimensions = useMemo(
    () => ({
      width: isMobile ? '98%' : '250px',
      height: isMobile ? 'auto' : '300px',
    }),
    []
  );

  // Use useMemo to prevent unnecessary re-renders of gallery items
  const galleryItems = useMemo(() => {
    if (!images?.length) return [];

    return images.map((pic) => (
      <a
        href={pic.original}
        className="gallery-item"
        key={`p_${pic.picNumber}`}
        data-sub-html={`<div class="lightGallery-captions"><h4>${pic.commentTitle}</h4><p>${pic.comment}</p></div>`}
      >
        <img
          className="img-responsive"
          style={{
            maxWidth: dimensions.width,
            maxHeight: dimensions.height,
            margin: '5px',
            objectFit: 'cover',
          }}
          alt={pic.commentTitle}
          src={pic.original}
          loading="lazy"
        />
      </a>
    ));
  }, [images, dimensions]);

  useEffect(() => {
    try {
      if (images === null) {
        setLoading(true);
        return;
      }
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, [images]);

  if (error) {
    return <div className="error-message">Error loading gallery: {error}</div>;
  }

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '2rem' }}>
        <TheSpinner />
      </div>
    );
  }

  if (!images?.length) {
    return <h2>No pictures for this trip yet</h2>;
  }

  return (
    <div className="gallery-container">
      <LightGallery
        mode="lg-fade"
        speed={500}
        isMobile={isMobile}
        plugins={[lgThumbnail, lgZoom]}
        allowMediaOverlap={true}
        toggleThumb={true}
        enableDrag={false}
        enableSwipe={true}
        mousewheel={false}
        download={false}
        closable={true}
        loop={true}
        counter={true}
        slideEndAnimation={true}
        hideControlOnEnd={true}
        touchThreshold={10}
        enableTouchEvents={true}
        style={{
          border: 'solid black 2px',
          display: 'grid',
          gridTemplateColumns: `repeat(auto-fill, minmax(${
            isMobile ? '98%' : '250px'
          }, 1fr))`,
          gap: '10px',
          padding: '10px',
        }}
      >
        {galleryItems}
      </LightGallery>
    </div>
  );
};

export default TheGallery;
