import { useEffect, useState } from 'react';
import { Form, Button, Col, Image, Alert } from 'react-bootstrap';
import Resizer from 'react-image-file-resizer';
import { getPoints, getTrips, savePoint } from '../../api/tripsApi';
import { isSuccessResponse } from '../../api/apiUtils';
import { directions } from '../utils/tools';
import classes from './EditTrips.module.css';

const resetFormData = {
  pointNumber: '',
  pointName: '',
  longitude: '',
  latitude: '',
  distanceNoticalMiles: '',
  date: '',
  windDirection: '',
  windStrength: '',
  freeText: '',
  paddleDirection: '',
  image: null,
  password: '',
};

const EditTripsForm = () => {
  const [tripOptions, setTripOptions] = useState([]);
  const [tripPoints, setTripPoints] = useState([]);
  const [pointId, setPointId] = useState(null);
  const [error, setError] = useState('');
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPosition, setCurrentPosition] = useState({
    longitude: null,
    latitude: null,
  });
  const [formData, setFormData] = useState({
    tripId: '',
    point: '',
    ...resetFormData,
  });
  const [imageBase64, setImageBase64] = useState(null);

  const convertHTMLEntitiesToChars = (text) => {
    const parser = new DOMParser();
    const decodedString = parser.parseFromString(
      `<!doctype html><body>${text}`,
      'text/html'
    ).body.textContent;
    return decodedString;
  };

  useEffect(() => {
    const fetchTrips = async () => {
      setLoading(true);
      try {
        const response = await getTrips();

        if (isSuccessResponse(response)) {
          setTripOptions(response.data);
        } else {
          console.error('Error fetching trips:', response.message);
          setError(response.message || 'Failed to load trips');
        }
      } catch (error) {
        console.error('Error in fetchTrips:', error);
        setError('An unexpected error occurred while loading trips');
      } finally {
        setLoading(false);
      }
    };

    fetchTrips();

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCurrentPosition({
          longitude: position.coords.longitude,
          latitude: position.coords.latitude,
        });
      },
      (err) => {
        console.error('Geolocation error:', err);
      }
    );
  }, []);

  const calculateNextPointNumber = (points) => {
    if (!points || points.length === 0) {
      return 0;
    }

    const maxPointNumber = points.reduce((max, item) => {
      return item.pointNumber > max ? item.pointNumber : max;
    }, 0);

    // Add 1 to the maximum pointNumber to get the next pointNumber
    return maxPointNumber + 1;
  };

  const onTripSelect = async (e) => {
    setError('');
    setPointId(null);
    const tripId = e.target.value;

    if (!tripId) {
      setTripPoints([]);
      return;
    }

    setLoading(true);
    try {
      const response = await getPoints({ tripId });

      if (isSuccessResponse(response)) {
        const data = response.data;
        setTripPoints(data);
        const nextPointNumber = calculateNextPointNumber(data);

        setFormData((prevFormData) => ({
          ...prevFormData,
          tripId,
          point: '',
          pointNumber: nextPointNumber,
          longitude: currentPosition.longitude || '',
          latitude: currentPosition.latitude || '',
          distanceNoticalMiles: '',
          date: '',
          windDirection: '',
          windStrength: '',
          freeText: '',
          paddleDirection: '',
          pointName: '',
          image: null,
          password: '',
        }));
      } else {
        console.error('Error fetching points:', response.message);
        setError(response.message || 'Failed to load points for this trip');
      }
    } catch (error) {
      console.error('Error in onTripSelect:', error);
      setError('An unexpected error occurred while loading trip points');
    } finally {
      setLoading(false);
    }
  };

  const onPointSelect = async (e) => {
    const { name, value } = e.target;
    if (!value) {
      const tripId = formData.tripId;
      setFormData({
        ...resetFormData,
        tripId: tripId,
        longitude: currentPosition.longitude || '',
        latitude: currentPosition.latitude || '',
      });
      setPointId(null);
      return;
    }

    if (!tripPoints || tripPoints.length === 0) {
      setError('No points found for this trip.');
      return;
    }

    const point = tripPoints.find((point) => point._id === value);
    if (!point) {
      setError('Selected point not found.');
      return;
    }

    setPointId(point._id);
    setFormData((prevFormData) => ({
      [name]: value,
      tripId: prevFormData.tripId,
      pointNumber: point?.pointNumber || calculateNextPointNumber(tripPoints),
      longitude: point?.longitude || '',
      latitude: point?.latitude || '',
      distanceNoticalMiles: point?.distanceNoticalMiles || '',
      date: point?.date || '',
      windDirection: point?.windDirection || '',
      windStrength: point?.windStrength || '',
      freeText: point?.freeText || '',
      paddleDirection: point?.paddleDirection || '',
      pointName: point?.pointName || '',
      image: point?.image || null,
      password: '',
    }));
  };

  const onDirectionSelect = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const resizeFile = (file) => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        600,
        600,
        'WEBP',
        80,
        0,
        (uri) => {
          setImageBase64(uri);
          resolve(uri);
        },
        'base64'
      );
    });
  };

  const handleImageChange = async (e) => {
    try {
      const file = e.target.files[0];
      if (!file) return;

      const image = await resizeFile(file);
      setFormData((prevFormData) => ({ ...prevFormData, image }));
    } catch (err) {
      console.error('Error processing image:', err);
      setError('Failed to process the image. Please try again.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'tripId') {
      onTripSelect(e);
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
      return;
    }

    if (name === 'points') {
      onPointSelect(e);
      return;
    }

    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSaving(true);

    try {
      const response = await savePoint(formData, pointId);

      if (isSuccessResponse(response)) {
        setFormData({ ...resetFormData, tripId: formData.tripId });
        setPointId(null);

        // Refresh points list after saving
        const pointsResponse = await getPoints({ tripId: formData.tripId });
        if (isSuccessResponse(pointsResponse)) {
          setTripPoints(pointsResponse.data);
        }

        setImageBase64(null);
        setError(''); // Clear any previous errors
      } else {
        console.error('Error saving point:', response.message);
        setError(response.message || 'Failed to save the point');
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      setError('An unexpected error occurred while saving the point');
    } finally {
      setSaving(false);
    }
  };

  return (
    <Col>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="tripId">
          <Form.Label className={classes.Label}>Trip</Form.Label>
          <Form.Control
            size="lg"
            as="select"
            name="tripId"
            value={formData.tripId}
            onChange={handleChange}
            required
          >
            <option value="" key="99999">
              Select a Trip
            </option>
            {tripOptions &&
              tripOptions.map((trip) => (
                <option key={trip.tripId} value={trip.tripId}>
                  {trip.tripName}
                </option>
              ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="pointId">
          <Form.Label className={classes.Label}>Point</Form.Label>
          <Form.Control
            size="lg"
            as="select"
            name="points"
            value={formData.point}
            onChange={handleChange}
            // required
          >
            <option value="" key="999999">
              New Point / Select a Point
            </option>
            {tripPoints &&
              tripPoints.map((point) => (
                <option key={point._id} value={point._id}>
                  {convertHTMLEntitiesToChars(
                    `${point.pointNumber} - ${point.pointName}`
                  )}
                </option>
              ))}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="pointNumber">
          <Form.Label className={classes.Label}>Point Number</Form.Label>
          <Form.Control
            size="lg"
            type="number"
            name="pointNumber"
            value={formData.pointNumber}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="pointName">
          <Form.Label className={classes.Label}>Point Name</Form.Label>
          <Form.Control
            size="lg"
            type="text"
            name="pointName"
            value={convertHTMLEntitiesToChars(formData.pointName)}
            onChange={handleChange}
            autoComplete="off"
            required
          />
        </Form.Group>

        <Form.Group controlId="longitude">
          <Form.Label className={classes.Label}>Longitude</Form.Label>
          <Form.Control
            size="lg"
            type="number"
            name="longitude"
            value={formData.longitude}
            onChange={handleChange}
            // required
          />
        </Form.Group>

        <Form.Group controlId="latitude">
          <Form.Label className={classes.Label}>Latitude</Form.Label>
          <Form.Control
            size="lg"
            type="number"
            name="latitude"
            value={formData.latitude}
            onChange={handleChange}
            // required
          />
        </Form.Group>

        <Form.Group controlId="distanceNoticalMiles">
          <Form.Label className={classes.Label}>
            Distance (Nautical Miles)
          </Form.Label>
          <Form.Control
            size="lg"
            type="number"
            name="distanceNoticalMiles"
            value={formData.distanceNoticalMiles}
            onChange={handleChange}
            // required
          />
        </Form.Group>

        <Form.Group controlId="paddleDirection">
          <Form.Label className={classes.Label}>Paddle Direction</Form.Label>
          <Form.Control
            as="select"
            value={formData.paddleDirection}
            onChange={onDirectionSelect}
            name="paddleDirection"
          >
            <option value="">Paddle Direction</option>
            {Object.entries(directions).map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="date">
          <Form.Label className={classes.Label}>Date</Form.Label>
          <Form.Control
            size="lg"
            type="date"
            name="date"
            value={formData.date.split('T')[0]}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="windDirection">
          <Form.Label className={classes.Label}>Wind Direction</Form.Label>
          <Form.Control
            as="select"
            value={formData.windDirection}
            onChange={onDirectionSelect}
            name="windDirection"
          >
            <option value="">Wind Direction</option>
            {Object.entries(directions).map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="windStrength">
          <Form.Label className={classes.Label}>Wind Strength</Form.Label>
          <Form.Control
            size="lg"
            type="number"
            name="windStrength"
            value={formData.windStrength}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="freeText">
          <Form.Label className={classes.Label}>Free Text</Form.Label>
          <Form.Control
            size="lg"
            as="textarea"
            rows={8}
            name="freeText"
            value={convertHTMLEntitiesToChars(formData.freeText)}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <hr />
        <Form.Group controlId="image">
          <Form.Label className={classes.Label}>Image</Form.Label>
          <Form.Control
            type="file"
            name="image"
            label="Choose an image"
            onChange={handleImageChange}
            accept="image/*"
          />
        </Form.Group>
        <hr />
        {error && <div className={classes.Error}>{error}</div>}
        <Button
          variant="primary"
          type="submit"
          style={{
            marginTop: '20px',
            marginBottom: '100px',
            width: '100%',
          }}
          disabled={saving}
        >
          Submit
        </Button>
      </Form>
      <hr />
      <Image src={imageBase64} style={{ width: '100%' }} />
      <hr />
    </Col>
  );
};

export default EditTripsForm;
