import React from 'react';
import Button from 'react-bootstrap/Button';
import { MdLocationOn, MdWaves } from 'react-icons/md';
import { FaUmbrellaBeach } from 'react-icons/fa';
import TheSpinner from './utils/TheSpinner';
import './QuickAccessBeaches.css';

const QUICK_ACCESS_BEACHES = [
  {
    name: 'Bat Galim Beach',
    id: 'bat_galim',
    icon: <FaUmbrellaBeach size={14} />,
  },
  {
    name: 'Caesarea Aqueduct Beach',
    id: 'caesarea_aqueduct',
    icon: <FaUmbrellaBeach size={14} />,
  },
  {
    name: 'Michmoret Beach',
    id: 'michmoret',
    icon: <FaUmbrellaBeach size={14} />,
  },
  {
    name: 'Hilton Beach',
    id: 'hilton_beach',
    icon: <FaUmbrellaBeach size={14} />,
  },
  {
    name: 'Hayarkon TLV',
    id: 'tlv_yarkon',
    icon: <MdWaves size={14} />,
  },
];

const QuickAccessBeaches = ({
  surfSpots,
  isLoading,
  onBeachSelect,
  loadingSpots,
}) => {
  // Filter out beaches that don't exist in our data
  const availableBeaches = QUICK_ACCESS_BEACHES.filter((beach) =>
    surfSpots.some(
      (spot) =>
        spot.id === beach.id ||
        spot.name.toLowerCase().includes(beach.name.toLowerCase()) ||
        (beach.id === 'tlv_yarkon' &&
          spot.name.toLowerCase().includes('tel aviv'))
    )
  );

  if (loadingSpots) {
    return (
      <div className="loading-container">
        <TheSpinner size="sm" /> Loading beaches...
      </div>
    );
  }

  if (availableBeaches.length === 0) {
    return null; // Don't show the section if no beaches are available
  }

  return (
    <div className="quick-access-section">
      <h5>
        <MdLocationOn className="location-icon" />
        Quick Access Beaches
      </h5>
      <div className="quick-access-buttons">
        {availableBeaches.map((beach) => (
          <Button
            key={beach.id}
            variant="outline-primary"
            onClick={() => onBeachSelect(beach.name)}
            disabled={isLoading}
            size="sm"
            className="quick-access-button"
          >
            {beach.icon}
            {beach.name}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default QuickAccessBeaches;
